/** @jsxImportSource @emotion/react */
import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Timelapse from '@mui/icons-material/Timelapse'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Work from '@mui/icons-material/Work'
import Chip from '@mui/material/Chip'
import { titleize, pluralize, translateRGBAColor } from '../../utils'
import { TimesheetStatus } from '../../constants'


export default class TimesheetSummary extends Component {

  static propTypes = {
    timesheet: PropTypes.object.isRequired,
    approver: PropTypes.object.isRequired,
    onApprove: PropTypes.func.isRequired,
    onUnapprove: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
    disableActions: PropTypes.bool
  }

  static styles = {
    card: {
      marginBottom: 20,
    },
    cardContent: {
      paddingTop: 0,
    },
    listIcon: {
      fontSize: 10,
    },
    listItem: {
      padding: 0,
      lineHeight: '16px',
      cursor: 'pointer',
      minHeight: 60,
    },
    approved: '#4CAF50',
    unapproved: '#FF5722',
    pending: '#FFC107',
  }

  renderAction = () => {
    const { timesheet: { attributes: { summary: { items } } }, onApprove, onUnapprove, approver } = this.props
    // approved all projects from the timesheet?
    let approved = items.every(p => p.approval.users.some(u => u.id + '' === approver.id + '' && u.status === TimesheetStatus.approved))

    return (
      <Button
        color="primary"
        variant="contained"
        children={approved ? 'Unapprove' : 'Approve'}
        onClick={() => approved ? onUnapprove() : onApprove()}
      />
    )
  }

  renderTimeIcon = (time, status) => (
    <Avatar
      style={{ fontSize: 10, color: 'white', backgroundColor: TimesheetSummary.styles[status] }}
      size={35}
    >
      {time}h
    </Avatar>
  )

  renderProjectStatus = project => (
    <span>
      <b> {pluralize('hour', project.time, true)} </b>
      {project.approval.users.map(u => `${titleize(u.status)} by ${u.name}`).join(', ')}
    </span>
  )

  renderProjectCode = project => (
    <div style={{ width: 60, display: 'inline-block' }}>
      <Chip
        label={project.code}
        style={{ color: 'white', backgroundColor: translateRGBAColor(project.color), fontSize: 10, height: 24 }}
      />
    </div>
  )

  renderProjectItem = project => (
    <ListItemWithNestedList
      key={project.id}
      button
      style={TimesheetSummary.styles.listItem}
      nestedItems={project.projectTasks.map(task => this.renderProjectTaskItem(task))}>
      <ListItemIcon>
        {this.renderTimeIcon(project.time, project.approval.status)}
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={<span>{project.code && this.renderProjectCode(project)} {project.name}</span>}
        secondary={this.renderProjectStatus(project)} />
    </ListItemWithNestedList>
  )

  renderProjectTaskItem = (task, status) => (
    <ListItemWithNestedList
      button
      key={task.id}
      nestedItems={task.timesheetEntries.map(entry => this.renderTimesheetEntryItem(entry))}
      style={TimesheetSummary.styles.listItem}
    >
      <ListItemIcon>
        {this.renderTimeIcon(task.time, status)}
      </ListItemIcon>
      <ListItemText
        primary={(task.code ? `[${task.code}] ` : '') + task.name}
        secondary={<b>{pluralize('hour', task.time, true)}</b>} />
    </ListItemWithNestedList>
  )

  renderTimesheetEntryItem = entry => (
    <ListItem button key={entry.id} style={TimesheetSummary.styles.listItem}>
      <ListItemIcon>
        <Timelapse />
      </ListItemIcon>
      <ListItemText
        primary={`${entry.date}: ${entry.code ? `[${entry.code}]` : ''} ${entry.comment}`}
        secondary={<b>{pluralize('hour', entry.time, true)}</b>} />
    </ListItem>
  )

  render = () => {
    const {timesheet: {attributes: {summary: {items, time, internalTime, externalTime, groupBy}, user, project, status}}, disableActions} = this.props
    const internalExternal = (groupBy !== 'user' ? ` (${internalTime} internal, ${externalTime} external)` : '')
    return (
      <Card style={TimesheetSummary.styles.card}>
        <CardHeader
          style={{display: 'flex' }}
          title={groupBy === 'user' ? <span>{this.renderProjectCode(project)}{project.name}</span> : user.name}
          titleTypographyProps={{ style: { fontSize: 18 } }}
          subheader={<span style={{paddingLeft: groupBy === 'user' && 60}}><b>{pluralize('hour', time, true)}</b> {status}{internalExternal}</span>}
          avatar={<Avatar style={{ backgroundColor: 'transparent' }}> {groupBy === 'user' ? <Work style={{ color: '#ccc' }} /> : <AccountCircle style={{ color: '#ccc' }} />} </Avatar>}
        />

        <CardContent style={TimesheetSummary.styles.cardContent} css={{ maxWidth: 'initial' }}>
          <List>
            {items.map(this.renderProjectItem)}
          </List>
        </CardContent>

        {!disableActions &&
          <CardActions style={{ height: 40 }}>
            <div className='pull-right'>
              {this.renderAction()}
            </div>
          </CardActions>
        }
      </Card>
    )
  }
}

const ListItemWithNestedList = ({ children, nestedItems, ...props }) => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      <ListItem {...props} onClick={() => setOpen(!open)}>
        {children}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List style={{ marginLeft: 32 }}>
          {nestedItems}
        </List>
      </Collapse>
    </div>
  )
}