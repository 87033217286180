import React, { Component } from 'react'
import PropTypes from 'prop-types';
import LabeledSelect from './LabeledSelect'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import _ from 'lodash'

export class MultiSelect extends Component{

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    renderOptionText: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    floatingLabelText: PropTypes.string,
    hintText: PropTypes.string,
    style: PropTypes.object,
  }

  constructor(props){
    super(props)
    this.state = {
      selected: this.valueToSelected(props.value, props.options)
    }
  }

  componentDidUpdate = ({value, options}) => {
    if (!_.isEqual(this.props.value, value) || !_.isEqual(this.props.options, options)) {
      this.setState({selected: this.valueToSelected(value, options)})
    }
  }

  valueToSelected = (value, options) => (value === '*' ? options.map(o => o.id) : value)

  selectionRenderer = (options, values, renderOptionText) => {
    switch (values.length) {
    case 0:
      return ''
    case options.length:
      return 'All'
    case 1:
      return renderOptionText(options.find(o => o.id === values[0]))
    default:
      return `${values.length} selected`
    }
  }

  onMultiSelectChange = (values, options) => {
    let selected = values
    if (selected.includes('*')) {
      if (values.length === options.length+1) {
        selected = []
      } else {
        selected = options.map(o => o.id)
      }
    }

    this.setState({selected})
    this.props.onChange(selected.length === options.length ? '*' : selected)
  }


  render = () => {
    const {options, label, renderOptionText} = this.props
    const {selected} = this.state

    return (
      <LabeledSelect
        style={this.props.style}
        label={label}
        multiple={true}
        value={selected}
        onChange={({target: {value}}) => this.onMultiSelectChange(value, options)}
        renderValue={values => this.selectionRenderer(options, values, renderOptionText)}
        MenuProps={{variant: "menu"}}
      >
        <MenuItem value='*'>
          <Checkbox checked={selected.length === options.length} style={{padding: '2px', marginRight: '6px'}} />
          {`All ${label}`}
        </MenuItem>
        {options.map(optn => <MenuItem key={optn.id} value={optn.id}>
          <Checkbox checked={selected.includes(optn.id)} style={{padding: '2px', marginRight: '6px'}} />
          {renderOptionText(optn)}
        </MenuItem>)}
      </LabeledSelect>
    )

  }

}

export default MultiSelect
