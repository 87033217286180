import React, { Component, } from 'react'
import PropTypes from 'prop-types';

export class CenteredCard extends Component {

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  }

  wrapperStyle = () => {
    return {
      display: 'flex',           /* establish flex container */
      flexDirection: 'column',  /* make main axis vertical */
      justifyContent: 'center', /* center items vertically, in this case */
      alignItems: 'center',     /* center items horizontally, in this case */
      minHeight: '100%',
      width: '100%',
      background: '#fafafa'
    }
  }

  render = () =>
    <div style={{ ...this.wrapperStyle(), ...this.props.style }}>
      {this.props.children}
    </div>
}

export default CenteredCard
