import { Popper } from '@mui/base'
import { Card, CardContent, List, ListItemButton } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useEffect, useRef, useState } from 'react'

const SimpleAutocomplete = ({ popperStyle, focusFirstItem, value, fetchOptions, noMatchesError, validateText, onChange, renderOption, renderText, defaultOptions, errorText, ...props }) => {
  const [open, setOpen] = useState(false)
  const changedRef = useRef(false)
  const [text, setText] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [options, setOptions] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [internalError, setInternalError] = useState('')
  errorText = internalError || errorText

  const currentOptions = (text === '' && defaultOptions) ? defaultOptions : options

  renderText ||= (option) => {
    return option?.toString() || ''
  }

  useEffect(() => {
    setText(renderText(value))
  }, [value])

  const handleFocus = ({ target }) => {
    changedRef.current = false
    setSelectedIndex(-1)
    setOptions([])
    setAnchorEl(target)
    setText('')
    setOpen(true)
  }

  const handleChange = (option) => {
    setText(renderText(option))
    onChange(option)
  }

  const handleBlur = () => {
    setAnchorEl(null)
    setOpen(false)
    if (changedRef.current) {
      handleChange(currentOptions[selectedIndex] || null)
    } else {
      setText(renderText(value))
    }
  }

  const handleClick = (option) => () => {
    handleChange(option)
    changedRef.current = false
    setOpen(false)
    if (anchorEl) {
      anchorEl.blur()
    }
  }

  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === 38) { // Up
      changedRef.current ||= true
      setSelectedIndex((si) => (si > 0 ? si - 1 : si))
    }
    if (keyCode === 40) { // Down
      changedRef.current ||= true
      setSelectedIndex((si) => ((si + 1) < currentOptions.length ? si + 1 : si))
    }
    if (keyCode === 13 && anchorEl) { // Enter
      anchorEl.blur()
    }
    if (keyCode === 27 && anchorEl) { // Escape
      changedRef.current = false
      anchorEl.blur()
    }
  }

  const preventDefault = (e) => e.preventDefault()

  const handleTextChange = async ({ target: { value: text } }) => {
    if (validateText && !validateText(text)) {
      return
    }
    changedRef.current ||= true
    setText(text)
    if (fetchOptions) {
      const options = await fetchOptions(text) || []
      setOptions(options)
      setSelectedIndex(0)
      setInternalError(options.length > 0 || text === '' ? '' : noMatchesError)
    }
  }

  return <>
    <TextField
      {...props}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleTextChange}
      value={text}
      onKeyDown={handleKeyDown}
      helperText={errorText}
      error={!!errorText}
    />
    <Popper anchorEl={anchorEl} style={{ zIndex: 50000, minWidth: anchorEl?.clientWidth, ...popperStyle }} open={open && !!currentOptions.length} placement='bottom-start'>
      <Card>
        <CardContent style={{ padding: 8, maxHeight: 400, overflowY: 'auto' }}>
          <List>
            {currentOptions.map((option, i) =>
              <ListItemButton key={i} selected={selectedIndex === i} onClick={handleClick(option)} onMouseDown={preventDefault} style={{ padding: 12 }}>
                {renderOption(option, i)}
              </ListItemButton>)}
          </List>
        </CardContent>
      </Card>
    </Popper>
  </>
}

export default SimpleAutocomplete