import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import Timer from '@mui/icons-material/Timer'
import { elapsedTime } from '../utils'
import { TimerStatus } from '../constants'

const styles = {
  avatarOpen: { color: '#444', height: 24, width: 24, padding: 4, marginLeft: 0 },
  avatarClosed: { color: '#444', height: 24, width: 24, padding: 4 },
}

export class TimerDisplay extends Component {

  static propTypes = {
    time: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }

  renderIcon = style =>
    <Avatar style={style}>
      <Timer />
    </Avatar>

  render = () => {
    const { time, status, open, onClick } = this.props

    if (open || time) {
      return (
        <Chip
          avatar={this.renderIcon(styles.avatarOpen)}
          label={elapsedTime(time)}
          style={{ margin: 10, backgroundColor: status === TimerStatus.started ? '#FF7043' : '#FAFAFA' }}
          onClick={onClick}
        />
      )
    } else {
      return (
        <div style={{ cursor: 'pointer' }} onClick={onClick}>
          {this.renderIcon(styles.avatarClosed)}
        </div>
      )
    }
  }
}

export default TimerDisplay
