import ActionSet from './ActionSet'
import { TokensAPI } from '../api'
import { SnackBarActions } from '.'
import jwtDecode from 'jwt-decode'
import { push } from 'connected-react-router'
import { SessionStatus } from '../constants'
import requestServiceConstantsMiddleware from '../middleware/requestServiceConstantsMiddleware'

export class SessionActionSet extends ActionSet{

  static initialState = {
    sessionStatus: SessionStatus.UNKNOWN,
    errors: {},
    user: {
      attributes: {}
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]
  /**
   * Check the current authentication token
   */
  static checkToken(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(() =>{
      return async (dispatch) => {
        try {
          await dispatch({
            type: this.CHECK_TOKEN,
            api: TokensAPI.check()
          })
          await dispatch(this.checkTokenSetUser())
        }
        catch(_notLoggedIn){
          // console.error(_notLoggedIn)

          // dispatch(this.signOut())
          dispatch(push('/'))
        }
      }
    })

    reducer({
      [this.CHECK_TOKEN_SUCCESS]: (state) => {
        return {...state, sessionStatus: SessionStatus.AUTHENTICATED}
      },
      [this.CHECK_TOKEN_FAILURE]: (state) => {
        return {...state, sessionStatus: SessionStatus.UNAUTHENTICATED}
      }
    })
  }

  static checkTokenSetUser(creator, reducer){
    creator(() => {
      return {
        type: this.CHECK_TOKEN_SET_USER,
        payload: window.localStorage.getItem('token')
      }
    })

    reducer({
      [this.CHECK_TOKEN_SET_USER]: (state, payload) => {
        const { user: {data: user} } = jwtDecode(payload)
        return {...state, user }
      }
    })
  }
  /**
   * Attempt to sign in given some credentials
   */
  static signIn(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((credentials) => async dispatch => {
      try {
        window.localStorage.removeItem('token')
        await dispatch({
          type: this.SIGN_IN,
          api: TokensAPI.get(credentials)
        })
      }
      catch({ body: { errors } }) {
        dispatch(SnackBarActions.alert(errors.map(({ title }) => title).join(', ')))
      }
      finally {
        dispatch(push('/'))
      }
    })

    reducer({
      [this.SIGN_IN_REQUEST]: (state) => ({
        ...state,
        sessionStatus: SessionStatus.UNKNOWN,
        errors: {}
      }),
      [this.SIGN_IN_SUCCESS]: (state, { result: { body: { token } } }) => {
        window.localStorage.setItem('token', token)
        const { user: { data: user }, user_id } = jwtDecode(token)

        return {
          ...state,
          token,
          user,
          user_id,
          sessionStatus: SessionStatus.AUTHENTICATED
        }
      },
      [this.SIGN_IN_FAILURE]: (state, { error: { body: { errors }}}) => ({
        ...state,
        sessionStatus: SessionStatus.UNAUTHENTICATED,
        errors: {
          [this.SIGN_IN]: errors
        }
      })
    })
  }

  /**
   * Use the Tokens API to request a password reset email
   */
  static requestPasswordReset(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((email) => async dispatch => {
      await dispatch({
        type: this.REQUEST_PASSWORD_RESET,
        api: TokensAPI.requestReset(email)
      })
      dispatch(SnackBarActions.alert('Password Reset Sent!'))
    })

    reducer({
      [this.REQUEST_PASSWORD_RESET]: (state) => {
        return {...state, errors: {}}
      },
      [this.REQUEST_PASSWORD_RESET_SUCCESS]: (state) => {
        return {...state}
      },
      [this.REQUEST_PASSWORD_RESET_FAILURE]: (state, { error: { body: { errors }}}) => {
        return {...state, errors: { [this.REQUEST_PASSWORD_RESET] : errors }}
      }
    })
  }

  /**
   * Use the Tokens API to reset a password for a user
   */
  static resetPassword(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(credentials => async dispatch => {
      try {
        const {body: {data: {attributes: { email }}}} = await dispatch({
          type: this.RESET_PASSWORD,
          api: TokensAPI.resetPassword(credentials)
        })
        await dispatch(this.signIn({ email, password: credentials.password}))
      }
      catch({ body, body: { errors }}) {
        dispatch(SnackBarActions.alert(errors.map(({ title }) => title).join(', ')))
      }
    })

    reducer({
      [this.RESET_PASSWORD]: (state) => {
        return {...state, errors: {}}
      },
      [this.RESET_PASSWORD_SUCCESS]: (state) => {
        return {...state}
      },
      [this.RESET_PASSWORD_FAILURE]: (state, { error: { body: { errors }}}) => {
        return {...state, errors: { [this.RESET_PASSWORD] : errors }}
      },
    })
  }

  /**
   * Refresh token for currently signed in user
   */
  static refreshToken(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(() => async dispatch => {
      const { body: { token } } = await dispatch({
        type: this.REFRESH_TOKEN,
        api: TokensAPI.refresh()
      })
      window.localStorage.setItem('token', token)
      return token
    })

    reducer({
      [this.REFRESH_TOKEN_SUCCESS]: (state, { result: { body: { token }}}) => ({
        ...state,
        token,
      }),
      [this.REFRESH_TOKEN_FAILURE]: (state, { error: { body: { errors }}}) => ({
        ...state,
        sessionStatus: SessionStatus.UNAUTHENTICATED,
        errors: { [this.REFRESH_TOKEN]: errors}
      })
    })
  }

  /**
   * Sign out the currently signed in user
   */
  static signOut(creator, reducer){
    creator(()  =>{
      return (dispatch) => {
        window.localStorage.removeItem('token')
        dispatch({
          type: this.SIGN_OUT,
          payload: {}
        })
        window.location = '/sessions/sign_in'
      }
    })

    reducer({})
  }

  /**
   * Use the Tokens API to set a password for a user
   */
  static setPassword(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(credentials => async dispatch => {
      try {
        const {body: {data: {attributes: { email }}}} = await dispatch({
          type: this.SET_PASSWORD,
          api: TokensAPI.setPassword(credentials)
        })
        await dispatch(this.signIn({ email, password: credentials.password}))
      }
      catch({ body, body: { errors }}) {
        dispatch(SnackBarActions.alert(errors.map(({ title }) => title).join(', ')))
      }
    })

    reducer({
      [this.SET_PASSWORD]: (state) => {
        return {...state, errors: {}}
      },
      [this.SET_PASSWORD_SUCCESS]: (state) => {
        return {...state}
      },
      [this.SET_PASSWORD_FAILURE]: (state, { error: { body: { errors }}}) => {
        return {...state, errors: { [this.SET_PASSWORD] : errors }}
      },
    })
  }
}

export default new SessionActionSet()
