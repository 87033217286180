/** @jsxImportSource @emotion/react */
import { Component, } from 'react'
import PropTypes from 'prop-types';
import { CenteredCard } from '../components'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography';

export class AuthForm extends Component {

  static propTypes = {
    errors: PropTypes.object.isRequired
  }

  render = () =>
    <CenteredCard style={styles.page}>
      <img
        src="/logo_white.png"
        alt="YouDo Logo"
        css={styles.logo} />
      <div style={{
        color: 'white',
        fontSize: '24px',
        letterSpacing: 0.5,
        fontVariant: 'small-caps',
        marginBottom: '1em'
      }}>
        Vitalstatistix Timekeeping
      </div>
      <Card onKeyUp={this.props.keyPressed} css={styles.card}>
        <CardHeader title={
          <Typography variant="h1" css={styles.header}>{this.props.title}</Typography>
        } />
        <CardContent>
          {this.props.children}
        </CardContent>
      </Card>
    </CenteredCard>
}

const styles = {
  page: {
    background: 'var(--primary-color)'
  },
  logo: {
    maxWidth: 256,
    '@media (min-height: 600px)': {
      marginTop: -96
    }
  },
  card: {
    minWidth: 'calc(100vw - 48px)',
    '@media (min-width: 1000px)': {
      minWidth: 360,
      padding: 24,
    }
  },
  header: {
    color: 'var(--primary-color)',
    fontSize: '36px',
    fontWeight: '400'
  }
}

export default AuthForm
