import React, { Component } from 'react'
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton'
import PlayArrow from '@mui/icons-material/PlayArrow'
import Stop from '@mui/icons-material/Stop'
import Pause from '@mui/icons-material/Pause'
import { TimerStatus } from '../constants'


export class TimerControl extends Component {

  static propTypes = {
    status: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    time: PropTypes.number.isRequired,
    style: PropTypes.object,
    iconStyle: PropTypes.object,
  }

  static styles = {
    icons: {
      [TimerStatus.stopped]: <PlayArrow />,
      [TimerStatus.started]: <Pause />,
      [TimerStatus.cleared]: <Stop />,
    }
  }

  get nextStatus(){ return this.props.status === TimerStatus.stopped ? TimerStatus.started : TimerStatus.stopped  }

  render = () => {
    const { onChange, style, iconStyle, status, time } = this.props

    return (
      <div style={{textAlign: 'center', ...style}}>
        <IconButton
          onClick={() => onChange(this.nextStatus)}
          style={iconStyle}
          tooltip={status === TimerStatus.stopped ? 'Start' : 'Pause'}
        >

          {TimerControl.styles.icons[status]}

        </IconButton>

        {!!time && status === TimerStatus.stopped &&

          <IconButton
            onClick={() => onChange(TimerStatus.cleared)}
            style={iconStyle}
            tooltip='Reset'
          >

            {TimerControl.styles.icons[TimerStatus.cleared]}

          </IconButton>

        }

      </div>
    )
  }
}

export default TimerControl
