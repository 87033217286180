import React, { Component, } from 'react'
import PropTypes from 'prop-types';
import { CenteredCard } from '../components'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { SessionActions } from '../actionsets'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AuthForm from './AuthForm';

export class ForgotPassword extends Component {

  static propTypes = {
    errors: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      error: ''
    }
    SessionActions.bindActions(this)
  }

  setEmail = ({ target: { value } }) => this.setState({ email: value, error: '' })

  sendReminder = () => {
    if (!this.state.email) return this.setState({ error: 'Email is blank' })
    this.actions.requestPasswordReset(this.state.email)
  }

  keyPressed = ({ which }) => which === 13 && this.sendReminder()

  errorText = () => this.props.errors[SessionActions.REQUEST_PASSWORD_RESET] ?
    this.props.errors[SessionActions.REQUEST_PASSWORD_RESET][0].title :
    this.state.error

  render = () =>
    <AuthForm title="Forgot Password" keyPressed={this.keyPressed}>
      <TextField size="medium" fullWidth ref={(ref) => ref && ref.focus()} label="Email" onChange={this.setEmail} value={this.state.email} helperText={this.errorText()} error={!!this.errorText()} />
      <Button style={styles.button} color="primary" variant="contained" onClick={this.sendReminder}>
        Send Email
      </Button>
      <Link style={styles.back} to='/sessions/sign_in'>Back</Link>
    </AuthForm>
}

const styles = {
  back: {
    fontSize: '0.8em'
  },
  button: {
    marginTop: 96,
    marginBottom: 32,
    float: 'right',
    minWidth: '50%'
  }
}

export default connect(({ session }) => session)(ForgotPassword)
