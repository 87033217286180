import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

const styles = {
  link: {
    color: '#555555',
    textDecoration: 'none',
  },
  iconDiv: {
    padding: 8,
    marginRight: 12,
    display: 'inline-block',
  },
}

export class MenuLink extends Component {

  static propTypes = {
    onMenuClick: PropTypes.func,
    name: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
  }

  renderIcon = () => this.props.icon && <FontAwesome title='icon' name={this.props.icon} fixedWidth style={{ paddingTop: 4 }} />

  render = () =>
    <Link to={!this.props.disabled && this.props.link} style={styles.link}>
      <MenuItem
        onClick={this.props.onMenuClick}
        disabled={this.props.disabled}
      >
        <div style={styles.iconDiv}>{this.renderIcon()}</div>{this.props.name}
      </MenuItem>
    </Link>
}

export default connect(state => state)(MenuLink)
