import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { push } from 'connected-react-router'
import Button from '@mui/material/Button';
import { TitleBar } from '../../components'
import Dialog from '../Dialog'

import { ProjectActions, CustomerActions, OAuthActions } from '../../actionsets'
import { FilterTypes } from '../../constants'
import { ListView, ListViewColumn } from '../../components'
import { ItemRenderers, ItemSorters } from '../../components/List'
import { IconDropdownMenu } from '../../components/Menu'
import { FilterBar } from '../FilterBar'
import { ProjectForm } from '.'
import { titleize } from '../../utils'
import { connect } from "react-redux";

export class Projects extends Component {

  static propTypes = {
    dispatch: PropTypes.func,
    projects: PropTypes.array.isRequired,
    external: PropTypes.shape({
      jira: PropTypes.object,
    }).isRequired,
    customers: PropTypes.array.isRequired,
  }

  static styles = {
    page: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    toolbar: {
      minHeight: 50
    },
    mainCard: {
      padding: 10
    },
    newButton: {
      float: 'right',
      marginLeft: 10
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      editId: null,
      filterState: FilterTypes.ACTIVE,
      selected: null,
      isShowingProjectForm: false,
    }

    ProjectActions.bindActions(this)
    CustomerActions.bindActions(this)
    OAuthActions.bindActions(this)
  }

  projectSelected = (selected) => {
    this.props.dispatch(push(`/projects/edit/${selected.id}`))
  }

  componentDidMount = () => {
    this.loadProjects()
    this.actions.loadCustomers()
  }

  loadProjects = async (query = this.filterToStatus(this.state.filterState)) => {
    await this.actions.searchProjects(query)
    const services = await this.actions.getServices()
    await this.actions.loadExternalProjects(Object.keys(services))
  }

  filterToStatus = filter => filter !== FilterTypes.ALL ? { status: filter.toLowerCase() } : {}

  onFilterChange = filter => {
    this.setState({ filterState: filter })
    this.loadProjects(this.filterToStatus(filter))
  }

  onFormSubmitted = success => {
    if (success) this.loadProjects()
    this.setState({ isShowingProjectForm: false })
  }

  handleNewProject = () => {
    this.setState({ isShowingProjectForm: true })
  }

  handleSelectExternalProject = project => {
    this.setState({ selected: project })
  }

  renderExternalProjects = projectSources => (
    Object.entries(projectSources).map(([name, source]) => ({
      title: titleize(name),
      logoSrc: require(`../../images/${name}.png`),
      subItems: source.projects
    }))
  )

  renderButtons = () => <>
    <Button
      color="primary"
      variant="contained"
      children='New'
      style={Projects.styles.newButton}
      onClick={this.handleNewProject}
    />
    <FilterBar filter={this.state.filterState} onFilterChange={this.onFilterChange} />
  </>

  render = () => (
    <div style={Projects.styles.page}>
      <TitleBar>Projects</TitleBar>
      <div style={Projects.styles.mainCard}>
        <Dialog
          title='New Project'
          fullWidth
          maxWidth='md'
          open={this.state.isShowingProjectForm}
          onClose={() => this.setState({ isShowingProjectForm: false })}
        >
          <ProjectForm
            {...this.props}
            externalProjectSource={
              <IconDropdownMenu
                onSelect={this.handleSelectExternalProject}
                items={this.renderExternalProjects(this.props.external)}
              />
            }
            selectedProject={this.state.selected}
            editId={this.state.editId}
            onSubmitted={() => this.onFormSubmitted(true)}
            onCancel={() => this.onFormSubmitted(false)}
          />
        </Dialog>

        <ListView
          pagination={{ pageSize: 10 }}
          items={this.props.projects}
          sortable={true}
          defaultSortBy='name'
          defaultSortOrder='asc'
          filtering={true}
          onRowClick={this.projectSelected}
          filterActions={this.renderButtons()}
        >
          <ListViewColumn name='Name' attribute='name' itemSorter={ItemSorters.stringPlainItemSorter} />
          <ListViewColumn name='Code' attribute='code' itemSorter={ItemSorters.stringPlainItemSorter} />
          <ListViewColumn name='Start Date' attribute='startDate' itemSorter={ItemSorters.stringPlainItemSorter} />
          <ListViewColumn name='End Date' attribute='endDate' itemSorter={ItemSorters.stringPlainItemSorter} />
          <ListViewColumn name='Status' attribute='derivedStatus' itemRenderer={ItemRenderers.titleizedItemRenderer} />
          <ListViewColumn name='Monthly hours cap' attribute='monthlyHoursCap' itemRenderer={ItemRenderers.plainItemRendererNullSafe} />
        </ListView>
      </div>
    </div>
  )
}

export default connect(({ projects, customers }) => ({ ...projects, ...customers }))(Projects)
