import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { UserForm } from '.'
import { UserRestrictedView } from '../'
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper'
import { connect } from "react-redux"
import { TitleBar } from '../../components'

export class UserFormWrapper extends Component{

  static propTypes = {
    user: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
  }

  get loaded(){
    return this.props.user.id
  }

  render = () =>
    <Paper style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <TitleBar>User</TitleBar>
      <Card style={{padding: 10}}>
        { this.loaded && <UserRestrictedView component={UserForm} {...this.props}/>}
      </Card>
    </Paper>
}

export default connect(state => { return { ...state.session} })(UserFormWrapper)