import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import '../styles/loader.css';
import { primary1Color } from '../styles/theme'

export class Loader extends Component {

  static propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
  }

  static defaultProps = {
    size: 200,
    //color: primary1Color
  }

  loaderStyle = () => {
    return {
      width: this.props.size,
      height: this.props.size,
      borderTopColor: this.props.color,
      borderBottomColor: this.props.color,
      borderRightColor: this.props.color,
    }
  }

  render = () =>
    <div className='loader' style={this.loaderStyle()}/>
}

export default Loader
