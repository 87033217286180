import { pink } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: { main: '#364b7d' },
    secondary: { main: pink['A200'] },
    gray: { main: '#CCCCCC', contrastText: '#000000', light: '#BBBBBB', dark: '#DDDDDD' },
    white: { main: '#FFFFFF', contrastText: '#000000', dark: '#F0F0F0' },
    darkGray: { main: '#333333', contrastText: '#FFFFFF' },
    mode: 'light'
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          '& .link-small': {
            margin: 5,
            textAlign: 'center',
            display: 'block',
            color: '#666565',
            fontSize: '0.8rem',
            '&:hover': {
              textDecoration: 'underline'
            }
          }
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          margin: '0 auto',
          width: '100%',
          boxSizing: 'border-box',
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: '10px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginBottom: '10px',
          '&+.MuiButton-root': {
            marginLeft: '10px'
          }
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: '56px !important', /* Match v0 Toolbar height */
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontSize: '20px',
          fontVariant: 'small-caps',
          letterSpacing: 0.5,
          color: 'rgba(0, 0, 0, 0.4)'
        }
      }
    }
  }
})