/** @jsxImportSource @emotion/react */
import React from 'react'
import { DatePicker as DPicker } from '@mui/x-date-pickers';
import moment from 'moment';

export const DatePicker = ({ name, value, onChange, style, className, clearable, fullWidth, error, helperText, ...rest }) => {

  const handleChange = date => {
    onChange && onChange({
      target: {
        value: date ? date.format('YYYY-MM-DD') : date
      }
    })
  }

  let actions = []
  if(clearable) {
    actions.push('clear')
  }

  return <DPicker
    format="DD/MM/YYYY"
    {...rest}
    onChange={handleChange}
    value={value ? moment(value) : null}
    slotProps={{
      textField: {
        style,
        className,
        fullWidth,
        error,
        helperText,
        sx: {'& .MuiInputAdornment-root': { paddingRight: '4px' }}
      },
      actionBar: {
        actions
      }
    }}
  />

}

export default DatePicker