/** @jsxImportSource @emotion/react */
import { Component }                from 'react'
import Select                              from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { uuid }                            from 'utils'
import MenuItem from '@mui/material/MenuItem';

export class LabeledSelect extends Component {

  constructor(props){
    super(props)
    this.htmlLabelId = uuid()
  }

  get children(){
    if(this.props.options){
      return Object.entries(this.props.options).map(([value, label], index) =>
        <MenuItem key={index} value={value}>{label}</MenuItem>
      )
    }else{
      return this.props.children
    }
  }

  render = () => {
    const {fullWidth, style, className, label, error, children, options, helperText, labelStyle, size: sizeProp, variant, ...props } = this.props
    const size = sizeProp || 'small'

    return (
      <FormControl fullWidth={!!fullWidth} error={!!error} style={style} className={className} size={size} variant={variant}>
        <InputLabel id={this.htmlLabelId} style={labelStyle} variant={variant}>{label}</InputLabel>
        <Select
          size={size}
          label={label}
          labelId={this.htmlLabelId}
          {...props}
          value={props.value !== undefined ? props.value : ''}
          MenuProps={{ disableScrollLock: true }}
        >
          {this.children}
        </Select>
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

}

export default LabeledSelect