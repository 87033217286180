import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

const styles = {
  toolbar: {
    minHeight: 50,
    backgroundColor: '#E8E8E8',
  },
}

const TitleBar = ({ children }) =>
  <Toolbar style={styles.toolbar}>
    <Typography variant="h5">{children}</Typography>
  </Toolbar>

export default TitleBar