import { SimpleAutocomplete } from "components"

const TimeInput = ({ increments, ...props }) => {
  const fetchOptions = async (text) => {
    if (text && validateText(text)) {
      let index = text.indexOf('.')
      let value = (index > -1) ? text.substring(0, index) : text
      let options = increments.map(incr => `${value}${incr}`).filter(option => option.includes(text) || parseFloat(option) === parseFloat(text))
      return options
    }
    return []
  }

  const renderOption = (option, i) => <>
    <div style={{ flex: '1 1 auto', paddingRight: '3em' }}>{option}</div>
    <div style={{ marginLeft: 'auto' }}>{floatToTime(option)}</div>
  </>

  return <SimpleAutocomplete
    {...props}
    fetchOptions={fetchOptions}
    renderOption={renderOption}
    validateText={validateText}
    popperStyle={{ minWidth: 220, zIndex: 10000 }} />
}

const validator = new RegExp('^[0-9]*\.{0,1}[0-9]*$')
const validateText = (text) =>
  text === '' || (!!text && validator.test(text))

const floatToTime = input => {
  let index = input.indexOf('.')
  let hours = input
  let minutes = ''
  if (index > -1) {
    hours = input.substring(0, index)
    minutes = parseFloat(input.substring(index)) * 60
  }
  return `${hours && hours + 'h'} ${minutes && minutes + 'min'}`
}

export default TimeInput