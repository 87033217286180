import React, { Component, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { connect } from 'react-redux'
import Drawer from '@mui/material/Drawer'
import NavigationClose from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import { isManagerOrAdmin } from '../utils'
import { UserRestrictedView, TaskTimer } from '../containers'
import { MenuLink, TimerDisplay } from '../components'
import { SessionActions, ProjectTaskActions } from '../actionsets'
import { RequestService, TimerStore } from '../services'
import { Routes } from './Routes'
import { SessionStatus } from "../constants"
import { push } from 'connected-react-router'

const TIMER_OPEN_KEY = 'open'

export class App extends Component {

  static propTypes = {
    time: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    SessionActions.bindActions(this)
    ProjectTaskActions.bindActions(this)
    this.state = {
      drawerOpen: false,
      timerOpen: !!TimerStore.get(TIMER_OPEN_KEY),
    }
  }

  componentDidMount = () => {
    if (this.props.sessionStatus === SessionStatus.AUTHENTICATED) {
      this.actions.loadRecentProjectTasks()
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.sessionStatus === SessionStatus.AUTHENTICATED && prevProps.sessionStatus !== this.props.sessionStatus) {
      if (RequestService.token) {
        this.actions.loadRecentProjectTasks()
      }
    }
  }

  closeMenu = () => this.setState({ drawerOpen: false })

  toggleTimer = () => {
    TimerStore.set(TIMER_OPEN_KEY, !this.state.timerOpen)
    this.setState({ timerOpen: !this.state.timerOpen })
  }

  get adminUser() {
    try {
      return this.props.user.attributes.role === 'admin'
    } catch (err) {
      return false
    }
  }

  styles = {
    titleContainer: {
      cursor: "pointer",
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      width: "auto",
      height: "36px",
    },
    title: {
      marginLeft: '20px',
      fontSize: '24px',
      letterSpacing: 0.5,
      fontVariant: 'small-caps',
    },
    titleBar: {
      color: 'white',
      backgroundColor: 'var(--primary-color)',
    },
    menuTitleBar: {
      color: 'white',
      backgroundColor: 'var(--primary-color)',
      paddingLeft: 12,
    },
    menuTitle: {
      fontSize: 24,
      paddingLeft: 4,
    },
    menuClose: {
      color: 'white',
    },
  }

  render = () => {
    // We need to check if the token is set due to the race condition that is triggered by
    // sessionStatus in the redux state being updated before the token is set in the localStorage
    if (this.props.sessionStatus !== SessionStatus.AUTHENTICATED || !RequestService.token) {
      return <Routes />
    }

    return (
      <div className='App'>
        <Toolbar style={this.styles.titleBar}>
          <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={() => this.setState({ drawerOpen: true })} >
            <MenuIcon />
          </IconButton>
          <div onClick={() => this.props.dispatch(push('/'))} style={this.styles.titleContainer}>
            <img src="/logo_white.png" alt="YouDo logo" style={this.styles.logo} />
            <div style={this.styles.title}>Vitalstatistix Timekeeping</div>
          </div>
          <IconMenu icon={<MoreVertIcon />}>
            <MenuItem disabled>{this.props.user.attributes && this.props.user.attributes.email}</MenuItem>
            <MenuItem value='0'
              children='My Details'
              onClick={() => this.props.dispatch(push(`/users/edit/${this.props.user.id}`))} />
            <MenuItem value='0'
              children='Sign out'
              onClick={this.actions.signOut} />
          </IconMenu>
          <TimerDisplay
            time={this.props.time}
            open={this.state.timerOpen}
            status={this.props.status}
            onClick={this.toggleTimer}
          />
        </Toolbar>

        <Drawer open={this.state.drawerOpen} onClose={() => this.setState({ drawerOpen: false })} PaperProps={{style: { minWidth: 240 }}}>
        <Toolbar style={this.styles.menuTitleBar}>
          <IconButton onClick={this.closeMenu}><NavigationClose style={this.styles.menuClose} /></IconButton>
          <div style={this.styles.menuTitle}>Menu</div>
        </Toolbar>

          <MenuLink name='Time Entry' link='/time' icon='clock-o' onMenuClick={this.closeMenu} />
          <MenuLink name='Reporting' link='/time/report' icon='pie-chart' onMenuClick={this.closeMenu} />

          <UserRestrictedView component={MenuLink} name='Approval' link='/time/approve' icon='thumbs-up' permit={p => isManagerOrAdmin(p.user)} onMenuClick={this.closeMenu} />
          <Divider />

          <UserRestrictedView component={MenuLink} name='Users' link='/users' icon='users' requiredRole='admin' onMenuClick={this.closeMenu} />
          <UserRestrictedView component={MenuLink} name='Customers' link='/customers' icon='address-card' requiredRole='admin' onMenuClick={this.closeMenu} />
          <UserRestrictedView component={MenuLink} name='Projects' link='/projects' icon='briefcase' requiredRole='admin' onMenuClick={this.closeMenu} />
          <UserRestrictedView component={MenuLink} name='Invoicing' link='/time/invoice' icon='book' requiredRole='admin' onMenuClick={this.closeMenu} />
          <UserRestrictedView component={Divider} includeProps={[]} requiredRole='admin' />

          <MenuLink name='Authenticated' link='/oauth/services' icon='cog' onMenuClick={this.closeMenu} />

        </Drawer>

        <TaskTimer open={this.state.timerOpen} onClose={this.toggleTimer} />

        <Routes />
      </div>
    )
  }
}

const IconMenu = ({ icon, children }) => {
  const [open, setOpen] = useState(false)
  const anchorEl = useRef(null)
  const handleClose = () => setOpen(false)
  return <>
    <IconButton onClick={(e) => { setOpen(true); }} style={{ color: 'white', padding: 6 }} ref={anchorEl}>
      {icon}
    </IconButton>
    <Menu open={open} anchorEl={anchorEl.current} onClick={handleClose} onClose={handleClose}>
      {children}
    </Menu>
  </>
}

export default connect(({ session, timer, router: { location } }) => ({ ...session, ...timer, location }))(App)
