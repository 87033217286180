import React, {  } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { SessionActions } from '../actionsets'
import { push } from 'connected-react-router'
import { Loader, CenteredCard } from '../components'
import { SessionStatus } from '../constants'

export class AuthenticationWrapper extends React.Component {

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    dispatch: PropTypes.func,
    location: PropTypes.object.isRequired,
    sessionStatus: PropTypes.string
  }

  constructor(props){
    super(props)
    SessionActions.bindActions(this)
    this.checkAuthentication(props.location.pathname, props.sessionStatus)
  }

  componentDidMount = () => {
    this.checkAuthentication(this.props.location.pathname, this.props.sessionStatus)
  }

  componentDidUpdate = () => {
    this.checkAuthentication(this.props.location.pathname, this.props.sessionStatus)
  }

  checkAuthentication(route, sessionStatus){
    if(sessionStatus === SessionStatus.AUTHENTICATED) return
    if(!this.shouldAuthenticateRoute(route)) return
    return sessionStatus === SessionStatus.UNKNOWN ?
      this.actions.checkToken() :
      this.props.dispatch(push('/sessions/sign_in'))
  }

  shouldAuthenticateRoute = (route) => {
    if(route.match(/^\/sessions/)) return false
    return true
  }

  pendingAuthentication = () => {
    return this.props.sessionStatus === SessionStatus.UNKNOWN &&
           this.shouldAuthenticateRoute(this.props.location.pathname)
  }

  body = () => {
    if(this.pendingAuthentication()){
      return <CenteredCard {...this.props} ><Loader/></CenteredCard>
    }else{
      return this.props.children
    }
  }

  render = () =>
    <div className='AuthenticationWrapper'>
      {this.body()}
    </div>
}

export default connect(({session, router: { location }}) => ({ ...session, location }))(AuthenticationWrapper)
