import thunk from 'redux-thunk'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import reduxPromiseMiddleware from '../middleware/reduxPromiseMiddleware'
import reduxAPIMiddleware from '../middleware/reduxAPIMiddleware'
import { reducers } from '../actionsets'
import { connectRouter, routerMiddleware, push } from 'connected-react-router'
import { compact, history } from '../utils'

const historyMiddleware = routerMiddleware(history)

const middlewares = compact([
  reduxAPIMiddleware,
  reduxPromiseMiddleware,
  historyMiddleware,
  thunk,
  (process.env.NODE_ENV === 'development' && process.env.REACT_APP_CONSOLE_OUTPUT !== 'false') ? require('redux-logger')({collapsed: true}) : null,
])

const reducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
})

const enhancedCreateStore = applyMiddleware(...middlewares)(createStore)
const store = enhancedCreateStore(reducer)

window.push = (location) => store.dispatch(push(location))

export default store
