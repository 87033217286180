/** @jsxImportSource @emotion/react */
import { Component, } from 'react'
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { SessionActions } from '../actionsets'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AuthForm from './AuthForm';

export class Login extends Component {

  static propTypes = {
    errors: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: ''
    }
    SessionActions.bindActions(this)
  }

  componentDidMount = () => this._emailInput && this._emailInput.focus()

  setEmail = ({ target: { value } }) => this.setState({ email: value })

  setPassword = ({ target: { value } }) => this.setState({ password: value })

  signinHandler = () => {
    const { email, password } = this.state
    this.actions.signIn({ email, password })
  }

  keyPressed = ({ which }) => which === 13 && this.signinHandler()

  errorText = () => this.props.errors[SessionActions.SIGN_IN] ?
    this.props.errors[SessionActions.SIGN_IN][0].title :
    null

  render = () =>
    <AuthForm title="Sign In" keyPressed={this.keyPressed}>
      <TextField size="medium" fullWidth ref={ref => this._emailInput = ref} label="Email" onChange={this.setEmail} value={this.state.email} variant='outlined' />
      <br />
      <TextField size="medium" fullWidth label="Password" type="password" onChange={this.setPassword} value={this.state.password} variant='outlined' helperText={this.errorText()} error={!!this.errorText()} css={styles.password} />
      <div>
        <Link to='/sessions/forgot_password' css={styles.forgot}>Forgot your password?</Link>
      </div>
      <Button variant="contained" color="primary" onClick={this.signinHandler} css={styles.button}>Sign In</Button>
    </AuthForm >
}

const styles = {
  password: {
    marginBottom: 4
  },
  forgot: {
    fontSize: '0.8em'
  },
  button: {
    marginTop: 96,
    marginBottom: 32,
    float: 'right',
    minWidth: '50%'
  }
}

export default connect(({ session }) => session)(Login)
