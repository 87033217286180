/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import FloatingActionButton from '@mui/material/Fab'
import ContentAdd from '@mui/icons-material/Add'
import Avatar from '@mui/material/Avatar'
import DeleteIcon from '@mui/icons-material/Delete'

import { TimesheetEntryActions, ProjectTaskActions, SnackBarActions } from '../../../actionsets'
import { TimeInput } from '../../../components'
import { ModelStatus } from '../../../constants'
import { isDirty, formatDate, assignIndexOf, partition, pipe } from '../../../utils'
import { ProjectTaskAutoComplete } from '../'

const styles = {
  error: {
    color: '#e23838',
    background: 'pink',
    padding: '15px 5px',
    borderLeft: '2px solid red'
  },

  entry: {
    display: 'flex',
    paddingBottom: 8,
  },

  fields: {
    flex: 1,
    display: 'flex',
  },

  actions: {
    flex: 0,
    minWidth: 100,
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 12,
    marginLeft: 20,
    fontSize: 18,
  },

  timeField: {
    flex: 0,
    minWidth: 80,
    marginRight: 10,
  },

  taskField: {
    flex: 1,
    marginRight: 10,
    width: 0,
  },

  commentField: {
    flex: 1,
    width: 0,
  },

  deleteButton: {
    minWidth: 40,
    maxHeight: 40,
    marginLeft: 10,
  }
}

export class TimesheetEntryForm extends Component {

  static propTypes = {
    entries: PropTypes.array.isRequired, // current timesheet entries edited in form state
    timesheetEntries: PropTypes.array.isRequired, // original saved timesheet entries retrieved from API
    projectTasks: PropTypes.array.isRequired,
    errors: PropTypes.array.isRequired,
    submissionErrors: PropTypes.array.isRequired,
    requestingTimesheetEntries: PropTypes.bool.isRequired,
    selectedDate: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    weekTime: PropTypes.number.isRequired,
    dispatch: PropTypes.func,
  }

  constructor(props) {
    super(props)
    TimesheetEntryActions.bindActions(this)
    ProjectTaskActions.bindActions(this)
    SnackBarActions.bindActions(this)
    this.state = {
      includeClosedTasks: false,
      errorsByKey: {}
    }
  }

  componentDidMount() {
    this.actions.setForm(this.props.timesheetEntries)
    this.actions.searchProjectTasks({ userId: this.userId, status: ModelStatus.active }, { createdAt: 'DESC', projectId: 'DESC' })
  }

  componentDidUpdate = (prevProps) => {
    if ((prevProps.requestingTimesheetEntries && !this.props.requestingTimesheetEntries) || !this.props.selectedDate.isSame(prevProps.selectedDate)) {
      this.actions.setForm(this.props.timesheetEntries)
    }
    // Map indexes to keys so they don't get lost when rows are deleted
    if (prevProps.errors !== this.props.errors) {
      const errorsByKey = (this.props.entries || []).reduce((accumulator, entry, index) => (
        { ...accumulator, [entry.id || entry.key]: (this.props.errors || {})[index] }
      ), {})
      this.setState({ errorsByKey })
    }
  }

  errorForField = (fieldName, key) => (this.state.errorsByKey[key] && this.state.errorsByKey[key][fieldName] ? this.state.errorsByKey[key][fieldName].join(', ') : null)

  prepareChangedEntries = entries => entries.filter(this.isEntryDirty).map(assignIndexOf(entries))

  submit = async () => {
    const { entries } = this.props

    if (!this.validate()) return

    const getEntriesForSubmit = pipe(this.prepareChangedEntries, partition(e => e.id))
    const [updates, creates] = getEntriesForSubmit(entries)

    await Promise.all([
      this.actions.updateTimesheetEntries(updates, false),
      this.actions.createTimesheetEntries(creates),
    ])
    this.actions.loadRecentProjectTasks()
  }

  validate = () => {
    let requiredFields = ['comment', 'projectTaskId']
    let errors = []

    const addError = (att, index, error) => {
      if (!errors[index]) errors[index] = {}
      if (!errors[index][att]) errors[index][att] = []
      errors[index][att].push(error)
    }

    this.props.entries.forEach((entry, index) => {

      requiredFields.forEach(fieldName => {
        if (!entry.attributes[fieldName]) addError(fieldName, index, 'is required')
      })

      if (isNaN(entry.attributes.time) || entry.attributes.time === '') {
        addError('time', index, 'is not a number')
      }

      if (entry.attributes.time.toString().includes('.') && !['.25', '.5', '.75'].find(incr => entry.attributes.time.toString().includes(incr))) {
        addError('time', index, 'is not a quarter hour')
      }

    })

    this.actions.setErrorsForm(errors)
    return errors.length === 0
  }

  deleteTimesheetEntry = async (entry, index) => {
    if (entry.id) {
      await this.actions.deleteTimesheetEntry(entry.id, index)
      await this.actions.deleteEntryForm(index)
    }
    else {
      await this.actions.deleteEntryForm(index)
    }
  }

  addTimesheetEntry = () => this.actions.addEntryForm(this.newTimesheetEntry)

  isEntryDirty = entry => isDirty(entry, this.props.timesheetEntries)

  renderSubmissionErrors = () => {
    if (!this.props.submissionErrors.length) {
      return
    }

    return this.props.submissionErrors.map(({ detail, source: { pointer } }, index) => {
      return (
        <p key={index} style={styles.error}>
          {`${pointer} ${detail}`.replace(/.*\//, '')}
        </p>
      )
    })
  }

  get isFormDirty() {
    return this.props.entries.some(this.isEntryDirty)
  }

  get userId() {
    return this.props.user.id
  }

  get totalTime() {
    return this.props.entries.reduce((total, entry) => {
      let time = entry.attributes.time
      if (!time || isNaN(time)) time = 0
      return total + parseFloat(time)
    }, 0)
  }

  get newTimesheetEntry() {
    return {
      id: null,
      key: Math.random().toString(36),
      attributes: {
        userId: this.userId,
        date: formatDate(this.props.selectedDate),
        time: '',
        projectTaskId: '',
        projectTaskCode: '',
        projectTaskName: '',
        comment: ''
      }
    }
  }

  get taskSearchStatusText() {
    return this.state.includeClosedTasks ? 'All Tasks' : 'Active tasks'
  }

  onLastFieldKeyDown = (e, index) => {
    if (e.nativeEvent.key === 'Tab' && !e.nativeEvent.shiftKey && index === this.props.entries.length - 1) {
      this.addTimesheetEntry()
    }
  }

  renderLockedStatus = () => (
    <Avatar size={45}>
      <FontAwesome title='locked' name='lock' fixedWidth style={{ color: '#fff' }} />
    </Avatar>
  )

  handleProjectTaskChange = (index) => (projectTask) => {
    this.actions.changeForm('projectTask', index, projectTask)
    this.actions.changeForm('projectTaskId', index, projectTask?.id)
    if (projectTask?.attributes?.defaultComment) {
      this.actions.changeForm('comment', index, projectTask.attributes.defaultComment)
    }
  }

  projectTaskLookup = {}
  projectTaskForEntry = (entry) => {
    const attributes = entry.attributes || {}
    if (attributes.projectTask) {
      return attributes.projectTask
    } else if (attributes.projectTaskId) {
      let projectTask = this.projectTaskLookup[attributes.projectTaskId]
      if (!projectTask) {
        const { projectTaskId, projectTaskCode, projectTaskName, projectCode } = attributes
        projectTask = { id: `${projectTaskId}`, type: 'projectTasks', attributes: { code: projectTaskCode, name: projectTaskName, projectCode } }
        this.projectTaskLookup[projectTaskId] = projectTask
      }
      return projectTask
    } else {
      return null
    }
  }

  renderTimesheetEntry = (entry, index) => (
    <div key={entry.id || entry.key} style={styles.entry}>
      <TimeInput
        variant="outlined"
        size="small"
        key={`time_${entry.id}`}
        increments={['', '.25', '.5', '.75']}
        label='Time'
        value={entry.attributes.time.toString()}
        onChange={(value) => this.actions.changeForm('time', index, value || '')}
        errorText={this.errorForField('time', entry.id || entry.key)}
        noMatchesError='is not a quarter hour'
        style={styles.timeField}
        disabled={this.props.disabled}
      />

      <ProjectTaskAutoComplete
        variant="outlined"
        size="small"
        key={`task_${entry.id}`}
        label='Task'
        value={this.projectTaskForEntry(entry)}
        errorText={this.errorForField('projectTaskId', entry.id || entry.key)}
        onChange={this.handleProjectTaskChange(index)}
        style={styles.taskField}
        disabled={this.props.disabled}
        includeClosedTasks={this.state.includeClosedTasks}
      />

      <TextField
        variant="outlined"
        size="small"
        label="Comment"
        value={entry.attributes.comment}
        onChange={e => this.actions.changeForm('comment', index, e.target.value)}
        error={!!this.errorForField('comment', entry.id || entry.key)}
        helperText={this.errorForField('comment', entry.id || entry.key)}
        style={styles.commentField}
        disabled={this.props.disabled}
        onKeyDown={e => this.onLastFieldKeyDown(e, index)}
      />
      <Button
        css={styles.deleteButton}
        disabled={this.props.disabled}
        onClick={() => this.deleteTimesheetEntry(entry, index)}
        style={{}}
        tabIndex={-1}
      >

        <DeleteIcon />
      </Button>
    </div>
  )

  render = () => {
    const { entries, selectedDate, requestingTimesheetEntries, pendingTimesheetEntriesLoad, disabled, weekTime } = this.props

    return (
      <div>
        <Card style={{ boxShadow: 'none', padding: 16 }}>
          <CardHeader
            title={
              <div>
                <span>{selectedDate.format('LL') + ((requestingTimesheetEntries || pendingTimesheetEntriesLoad) ? ' Loading..' : '')}</span>
                <Button style={{ float: 'right' }} variant="outlined" children={this.taskSearchStatusText} onClick={() => this.setState({ includeClosedTasks: !this.state.includeClosedTasks })} />
              </div>
            }
            subheader={`${this.totalTime} hours, ${this.totalTime + weekTime} this week ${disabled ? ', Approved' : ''}`}
            avatar={disabled ? this.renderLockedStatus() : undefined}
          />

          <CardContent style={{ maxWidth: 'initial' }}>
            {this.renderSubmissionErrors()}
            {entries.length > 0 ? entries.map(this.renderTimesheetEntry) :
              <div css={{ color: 'gray', border: 'dashed 1px lightgray', borderRadius: 4, padding: 10, userSelect: 'none' }}>No Entries</div>
            }

          </CardContent>

          {!disabled &&
            <CardActions css={{ display: 'flex', paddingRight: 16, paddingLeft: 16 }}>
              <FloatingActionButton color="primary" size="small" aria-label="add" onClick={this.addTimesheetEntry}>
                <ContentAdd />
              </FloatingActionButton>
              <span css={{ flex: 1 }} />
              <Button children='Save' variant="contained" color="primary" onClick={this.submit} disabled={!this.isFormDirty} css={{ marginBottom: 0 }} />
            </CardActions>
          }
        </Card>
      </div>
    )
  }
}


export default connect(state => ({
  ...state.session,
  ...state.timesheetEntry.form,
  pendingTimesheetEntriesLoad: state.timesheetEntry.pendingTimesheetEntriesLoad,
  projectTasks: state.projectTasks.projectTasks,
}))(TimesheetEntryForm)

