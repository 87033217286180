import React, {  } from 'react'
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import { connect } from 'react-redux'
import { SnackBarActions } from '../actionsets'

export class AppSnackBar extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      open: false,
      message: props.message
    }
    SnackBarActions.bindActions(this)
  }

  static propTypes = {
    message: PropTypes.string,
    dispatch: PropTypes.func
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.message !== prevProps.message) {
      this.setState({open: true})
    }
  }

  handleClose = () => {
    this.setState({open: false})
    this.actions.clearSnackbar()
  }

  render = () =>
    <Snackbar
      open={!!(this.props.message && this.state.open)}
      message={this.props.message}
      autoHideDuration={4000}
      onClose={this.handleClose}
    />
}

export default connect(({snackbar}) => snackbar)(AppSnackBar)