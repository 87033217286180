/** @jsxImportSource @emotion/react */
import React, {  } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { ProjectUserActions } from '../../actionsets'
import CRUDForm from '../../containers/CRUDForm'
import { ProjectUserAPI } from '../../api'
import { RequestService } from '../../services'
import { push } from 'connected-react-router'
import { roleCheck } from '../../utils'
import { LabeledCheckbox, LabeledSelect } from '../../components'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

export class ProjectUserForm extends CRUDForm{

  static propTypes = {
    dispatch: PropTypes.func,
    projects: PropTypes.array,
    projectId: PropTypes.any,
    users: PropTypes.array,
    onSubmitted: PropTypes.func,
    onCancel: PropTypes.func,
  }

  static styles = {
    fields: {
      display: 'flex',
      columnGap: 16,
      marginBottom: 20,
    },
    actions: {
      float: 'right'
    },
    selectField: {
      flex: 1,
      minWidth: 200,
      marginTop: 10
    },
  }

  static defaultProps = {
    projects: [],
    users: []
  }

  constructor(props){
    super(props)
    this.state = {
      errors: {},
      submissionErrors: [],
      projectId: this.props.projectId || '',
      userId: '',
      manager: false,
      projects: [],
      users: [],
    }
    ProjectUserActions.bindActions(this)
  }

  loadCreateItem = () => {
    if(this.createMode && !roleCheck(this.props.role, 'admin')){
      this.props.dispatch(push('/'))
    }
  }

  loadEditItem = async () => {
    if(this.userId !== this.editId && !roleCheck(this.props.role, 'admin')){
      this.props.dispatch(push('/'))
      return
    }
    try{

      const { body: { data: { attributes: { projectId, userId, manager }}}} = await ProjectUserAPI.show(this.editId)(RequestService)
      this.setState({
        projectId:  String(projectId), userId:  String(userId), manager
      })
    }catch(err){
      this.setState({ submissionErrors: [{ detail: `Couldn't find project user with id ${this.editId}`, source: { pointer:'' }}] })
    }
  }

  submit = async () => {
    if(!this.validate())
      return

    const { projectId, userId, manager } = {...this.state }
    const params = { projectId, userId, manager  }

    let action
    if(this.editMode){
      action = this.actions.updateProjectUser(this.editId, params)
    }else{
      action = this.actions.createProjectUser(params)
    }

    try{
      await action
      this.props.onSubmitted && this.props.onSubmitted()
    }catch(err){
      const { body: { errors: submissionErrors }} = err
      this.setState({ submissionErrors })
    }
  }

  validate = () => {
    let requiredFields = ['projectId', 'userId']
    const errors = this.requireFields(requiredFields)
    this.setState({ errors })
    return Object.keys(errors).length === 0
  }

  get editId(){
    return this.props.editId
  }

  get ProjectUserId(){
    return this.props.projectUser.id
  }

  get projectSelectField(){
    return (
      <LabeledSelect
        style={ProjectUserForm.styles.selectField}
        label="Project"
        value={this.state.projectId}
        onChange={this.onInputValueChange('projectId')}
        {...this.fieldErrors('projectId')}
      >
        <MenuItem value={''} children="" style={{minHeight: '32px'}} />
        { this.props.projects.map(project => <MenuItem key={`project-${project.id}`} value={project.id} children={project.attributes.name} style={{minHeight: '32px'}} />) }
      </LabeledSelect>
    )
  }

  get userSelectField(){
    return (
      <LabeledSelect
        style={ProjectUserForm.styles.selectField}
        label="User"
        value={this.state.userId}
        onChange={this.onInputValueChange('userId')}
        {...this.fieldErrors('userId')}
      >
        <MenuItem value={''} children="" style={{minHeight: '32px'}} />
        { this.props.users && this.props.users.map(user => <MenuItem key={`user-${user.id}`} value={user.id} children={user.attributes.name} style={{minHeight: '32px'}} />) }
      </LabeledSelect>
    )
  }

  render = () =>
    <div>
      <div style={ProjectUserForm.styles.fields}>
        {this.renderSubmissionErrors()}
        {this.props.projects.length > 0 && this.projectSelectField}
        {this.userSelectField}

        <LabeledCheckbox
          label="Manager"
          color="primary"
          checked={this.state.manager || false}
          onChange={({ target: { checked }}) => this.setState({manager: checked})}
          formControlStyle={{ marginTop: 10 }}
        />
      </div>
      <div style={ProjectUserForm.styles.actions}>
        <Button children="Cancel" style={{marginRight: 10}} onClick={this.props.onCancel} />
        <Button color="primary" variant="contained" children='Save' onClick={this.submit} />
      </div>
    </div>
}

export default connect(state => { return {...state.session} })(ProjectUserForm)
