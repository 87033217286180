/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem'
import FontAwesome from 'react-fontawesome'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import moment from 'moment'
import { MultiSelect, LabeledSelect, LabeledCheckbox, DatePicker } from '../../components'
import { titleize } from '../../utils'

export class TimesheetFilter extends Component {

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    users: PropTypes.array,
    customers: PropTypes.array,
    projects: PropTypes.array,
    user_id: PropTypes.array,
    customer_id: PropTypes.array,
    project_id: PropTypes.array,
  }

  static styles = {
    selectField: {
      marginRight: 10,
      flex: 1,
    },
    dateField: {
      marginRight: 10,
      flex: 1,
    },
    monthField: {
      marginRight: 10,
      flex: 1,
    },
    groupField: {
      width: 200,
      paddingRight: 16,
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      '& .MuiFormControl-root': {
        marginBottom: 0,
      }
    },
    toolbar: {
      display: 'flex',
      flexWrap: 'wrap-reverse',
      padding: 0,
      margin: 0,
      backgroundColor: '#EEEEEE',
      justifyContent: 'space-between',
      height: '80px',
      alignItems: 'stretch',
      borderRight: 'solid 6px var(--primary-color)',
    },
    download: {
      marginTop: 20,
      marginRight: 10,
      cursor: 'pointer',
      right: 10,
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      groupBy: 'user',
      user_id: props.user_id || '*',
      project_id: props.user_id || '*',
      customer_id: props.user_id || '*',
      summary: false
    }
  }

  componentDidMount = () => {
    this.props.onChange(this.filterToQueryParams(this.state))
  }

  toQueryParam = item => {
    if (moment.isMoment(item)) {
      return item.format('YYYY-MM-DD')
    }
    if (item === '*') {
      return undefined
    }
    if (item instanceof Array && item.length === 0) {
      return ['']
    }
    return item
  }

  filterToQueryParams = params => {
    let queryParams = {}
    Object.keys(params).forEach(key => queryParams[key] = this.toQueryParam(params[key]))
    return queryParams
  }

  onFilterChange = (params) => {
    let filterParams = { ...this.state, ...params }
    this.setState(filterParams)
    this.props.onChange(this.filterToQueryParams(filterParams))
  }

  get selectedMonth() {
    const { startDate, endDate } = this.state
    return startDate.month() === endDate.month() ? startDate.month() : ''
  }

  get filterToText() {
    const { startDate, endDate } = this.state
    let dateText = ''
    if (moment(startDate).startOf('month').isSame(startDate, 'day') && moment(startDate).endOf('month').isSame(endDate, 'day')) {
      dateText = `${startDate.format('MMMM YYYY')}`
    } else {
      dateText = `${startDate.format('MMMM Do')} to ${endDate.format('MMMM Do')}`
    }
    return `Time for ${dateText}`
  }

  renderMultiSelect = (model, att) => {
    let options = this.props[`${model}s`].sort((a, b) => (a.attributes[att] > b.attributes[att]) ? 1 : -1)
    let fieldName = `${model}_id`
    let label = titleize(model) + 's'

    return (
      <MultiSelect
        style={TimesheetFilter.styles.selectField}
        label={label}
        value='*'
        onChange={val => this.onFilterChange({ [fieldName]: val })}
        options={options}
        renderOptionText={optn => optn.attributes[att]}
      />
    )
  }

  renderMonthSelect = (forToolbar = false) => (
    <LabeledSelect
      css={forToolbar ? {} : TimesheetFilter.styles.monthField}
      label="Month"
      fullWidth={forToolbar}
      value={this.selectedMonth}
      onChange={({ target: { value } }) => this.onFilterChange({ startDate: moment().month(value).startOf('month'), endDate: moment().month(value).endOf('month') })}
    >
      <MenuItem value=''></MenuItem>
      {moment.months().map((month, index) => <MenuItem key={index} value={index}>{month}</MenuItem>)}
    </LabeledSelect>
  )

  renderGroupSelect = () => (
    <LabeledSelect
      label='Group By'
      value={this.state.groupBy}
      onChange={({ target: { value } }) => this.onFilterChange({ groupBy: value })}
      options={{ user: 'User', project: 'Project', week: 'Week', task: 'Task' }}
      fullWidth
    />
  )

  renderToolBar = mode => (
    <Toolbar style={TimesheetFilter.styles.toolbar}>
      <div style={{ paddingLeft: '20px', display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5">{this.filterToText}</Typography>
      </div>
      {['reporting', 'approval'].includes(mode) &&
        <div css={TimesheetFilter.styles.groupField}>
          {mode === 'reporting' && this.renderGroupSelect()}
          {mode === 'approval' && this.renderMonthSelect(true)}
        </div>}
    </Toolbar>
  )

  render = () => {
    // return <div></div>
    const { startDate, endDate } = this.state
    const { users, projects, customers, mode, onDownload } = this.props

    return (
      <div>

        {['reporting', 'invoicing'].includes(mode) &&
          <div style={{ padding: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginBottom: 5 }}>
              <span style={{ ...TimesheetFilter.styles.download, marginTop: '0px', color: '#337ab7', display: 'flex', alignItems: 'center' }} onClick={() => onDownload(this.filterToQueryParams(this.state))}>
                Download as CSV
                <FontAwesome title="download" name='cloud-download' fixedWidth style={{ fontSize: 25, marginLeft: 5 }} />
              </span>
              {!['week'].includes(this.state.groupBy) && <LabeledCheckbox
                label="Summary"
                checked={this.state.summary}
                onChange={({ target: { checked } }) => this.setState({ summary: checked })}
                formControlStyle={{ width: 'auto', whiteSpace: 'nowrap', marginTop: -5, marginRight: 0, marginBottom: 0 }}
              />}
            </div>

            <div style={{ display: 'flex', marginBottom: 10 }}>

              {this.renderMonthSelect()}

              <DatePicker
                css={TimesheetFilter.styles.dateField}
                label='From'
                value={startDate.toDate()}
                onChange={({ target: { value } }) => this.onFilterChange({ startDate: moment(value) })}
                autoOk
                mode='landscape'
                container='inline'
              />

              <DatePicker
                css={TimesheetFilter.styles.dateField}
                label='To'
                value={endDate.toDate()}
                onChange={({ target: { value } }) => this.onFilterChange({ endDate: moment(value) })}
                autoOk
                mode='landscape'
                container='inline'
              />

            </div>

            <div style={{ display: 'flex' }}>

              {users && this.renderMultiSelect('user', 'name')}
              {projects && this.renderMultiSelect('project', 'name')}
              {customers && this.renderMultiSelect('customer', 'businessName')}

            </div>

          </div>
        }

        {this.renderToolBar(mode)}

      </div>
    )
  }
}

export default TimesheetFilter