import { FormControl, FormHelperText, InputLabel, Popover } from "@mui/material"
import { useRef, useState } from "react"
import { SketchPicker } from "react-color"

// This has been styled for variant="standard" if we switch to variant="outlined" it will require new styles
const ColorPicker = ({ color, onChange, style, label, error, helperText, ...rest }) => {
  const anchorRef = useRef()
  const [open, setOpen] = useState(false)
  const rgba = color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : 'rgba(255, 255, 255, 1)'

  return (
    <FormControl style={{ ...style }} {...rest}>
      <InputLabel shrink={true} variant="outlined" style={{ background: 'white'}}>Color</InputLabel>
      <div ref={anchorRef} onClick={() => setOpen(o => !o)} style={{
        height: 38,
        border: 'solid 1px #BBB',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase'
      }}>
        <div style={{ width: 24, height: 24, borderRadius: 4, margin: 8, boxSizing: 'border-box', backgroundClip: 'content-box', backgroundColor: rgba }} />
        <div style={{ marginLeft: 8 }}>{rgba}</div>
      </div>
      <FormHelperText error={error}>{helperText}</FormHelperText>
      <Popover anchorEl={anchorRef.current} style={{ zIndex: 50000 }} open={open} onClose={() => setOpen(false)} {...popoverOrigin}>
        <SketchPicker color={color} onChange={onChange} />
      </Popover>
    </FormControl>
  )
}

const popoverOrigin = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  }
}

export default ColorPicker