import React, { } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { push } from 'connected-react-router'
import Button from '@mui/material/Button'
import CRUDForm from '../../containers/CRUDForm'
import { ModelStatus } from '../../constants'
import { ProjectActions } from '../../actionsets'
import { roleCheck, randomColor, titleize } from '../../utils'
import { ColorPicker, DatePicker, LabeledSelect, TimeInput } from 'components'

export class ProjectForm extends CRUDForm {

  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      submissionErrors: [],
      customerId: '',
      name: '',
      code: '',
      source: '',
      description: '',
      url: '',
      startDate: null,
      endDate: null,
      status: ModelStatus.default,
      customerStatus: '',
      combinedStatus: '',
      color: this.createMode ? randomColor() : undefined
    }

    ProjectActions.bindActions(this)
  }

  static propTypes = {
    dispatch: PropTypes.func,
    onSubmitted: PropTypes.func,
    onCancel: PropTypes.func,
    customers: PropTypes.array,
    externalProjectSource: PropTypes.element,
  }

  static styles = {
    form: {
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'column'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1
    },
    leftField: {
      flex: 1,
      marginRight: 10
    },
    rightField: {
      flex: 1,
      marginLeft: 10
    },
    actions: {
      float: 'right'
    },
    colorField: {
      height: 72,
      cursor: 'auto',
      display: 'flex'
    },
    label: {
      color: 'rgba(0, 0, 0, 0.3)'
    }
  }

  static defaultProps = {
    customers: []
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.selectedProject !== this.props.selectedProject) {
      this.setState({ ...this.props.selectedProject })
    }
  }

  loadEditItem = async () => {
    if (!roleCheck(this.userRole, 'admin')) {
      this.props.dispatch(push('/'))
      return
    }
    const attributes = this.props.project.attributes
    this.setState(attributes)
    this.setState({
      url: attributes.url || '',
      color: attributes.color || randomColor(),
      customerId: String(attributes.customerId)
    })
  }

  submit = async () => {
    if (!this.validate()) return

    const { name, code, source, description, url, customerId, startDate, endDate, status, color, monthlyHoursCap } = { ...this.state }
    const params = { name, code, source, description, url, customerId, startDate, endDate, status, color, monthlyHoursCap }

    let action
    if (this.editMode) {
      action = this.actions.updateProject(this.editId, params)
    } else {
      action = this.actions.createProject(params)
    }

    try {
      await action
      this.props.onSubmitted && this.props.onSubmitted()
    } catch (err) {
      const { body: { errors: submissionErrors } } = err
      this.setState({ submissionErrors })
    }
  }

  validate = () => {
    let requiredFields = ['name', 'code', 'description', 'customerId', 'status']
    const errors = this.requireFields(requiredFields)
    this.setState({ errors })
    return Object.keys(errors).length === 0
  }

  get userId() {
    return this.props.user.id
  }

  get userRole() {
    return this.props.user?.attributes?.role
  }

  get editId() {
    return this.props.editId || this.props.match.params.editId
  }

  get derivedStatusError() {
    return this.state.customerStatus === ModelStatus.archived ? 'The customer has been archived' : ''
  }

  onColorChange = color => this.setState({ color: color.rgb })

  renderFormFields = () => (
    <div style={ProjectForm.styles.form}>
      {this.renderSubmissionErrors()}
      <div style={ProjectForm.styles.row}>
        <TextField
          style={ProjectForm.styles.leftField}
          label="Name"
          value={this.state.name}
          onChange={this.onInputValueChange('name')}
          {...this.fieldErrors('name')}
        />
        {this.props.externalProjectSource}
        <TextField
          style={ProjectForm.styles.rightField}
          label="Code"
          value={this.state.code}
          onChange={this.onInputValueChange('code')}
          {...this.fieldErrors('code')}
        />
      </div>
      <div style={ProjectForm.styles.row}>
        <DatePicker
          clearable
          style={ProjectForm.styles.leftField}
          label="Start Date"
          value={this.state.startDate}
          {...this.fieldErrors('startDate')}
          onChange={this.onInputValueChange('startDate')}
          autoOk
        />
        <DatePicker
          clearable
          style={ProjectForm.styles.rightField}
          label="End Date"
          value={this.state.endDate}
          {...this.fieldErrors('endDate')}
          onChange={this.onInputValueChange('endDate')}
          autoOk
        />
      </div>
      <div style={ProjectForm.styles.row}>
        <TextField
          style={ProjectForm.styles.leftField}
          label="Description"
          value={this.state.description}
          onChange={this.onInputValueChange('description')}
          {...this.fieldErrors('description')}
        />
        <TextField
          style={ProjectForm.styles.rightField}
          label="URL"
          value={this.state.url}
          onChange={this.onInputValueChange('url', this.nthValue(1))}
          {...this.fieldErrors('url')}
        />
      </div>
      <div style={ProjectForm.styles.row}>
        <LabeledSelect
          style={ProjectForm.styles.leftField}
          label="Customer"
          value={this.state.customerId}
          onChange={this.onInputValueChange('customerId')}
          {...this.fieldErrors('customerId')}
        >
          <MenuItem value='' children="" style={{ minHeight: '32px' }} />
          {this.props.customers.map(customer => <MenuItem key={'customer-' + customer.id} value={customer.id} children={customer.attributes.businessName} style={{ minHeight: '32px' }} />)}
        </LabeledSelect>
        <LabeledSelect
          style={ProjectForm.styles.rightField}
          label="Status"
          value={this.state.status}
          onChange={this.onInputValueChange('status')}
          error={!!(this.state.errors.status || this.derivedStatusError)}
          helperText={this.state.errors.status || this.derivedStatusError}
          disabled={!!this.derivedStatusError}
        >
          <MenuItem value={ModelStatus.active} children='Active' />
          <MenuItem value={ModelStatus.archived} children='Archived' />
        </LabeledSelect>
      </div>
      <div style={ProjectForm.styles.row}>
        <ColorPicker
          style={ProjectForm.styles.leftField}
          color={this.state.color}
          onChange={this.onColorChange}
          label={this.state.code}
        />
        <LabeledSelect
          style={ProjectForm.styles.rightField}
          label="Source"
          value={this.state.source}
          onChange={this.onInputValueChange('source')}
          {...this.fieldErrors('source')}
        >
          <MenuItem value={null} children={""} />
          {Object.keys({ ...this.props?.oauth?.services }).map(serviceName =>
            <MenuItem key={serviceName} value={serviceName} children={titleize(serviceName)} />
          )}
        </LabeledSelect>
      </div>
      <div style={ProjectForm.styles.row}>
        <TimeInput
          increments={['']}
          label='Monthly hours cap'
          value={this.state.monthlyHoursCap ? `${this.state.monthlyHoursCap}` : ''}
          onChange={(value) => this.setState({ monthlyHoursCap: value })}
          errorText={this.state.errors.monthlyHoursCap}
          noMatchesError='is not a quarter hour'
          style={ProjectForm.styles.leftField}
          disabled={false}
        />
        <div style={ProjectForm.styles.rightField} />
      </div>
    </div>
  )

  render = () => (
    <div>
      {this.renderFormFields()}
      <div style={ProjectForm.styles.actions}>
        <Button children="Cancel" style={{ marginRight: 10 }} onClick={this.props.onCancel} />
        <Button variant="contained" color="primary" children='Save' onClick={this.submit} />
      </div>
    </div>
  )
}

export default connect(state => { return { ...state.session, oauth: state.oauth } })(ProjectForm)
