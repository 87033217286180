import React, {  } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { CustomerActions } from '../../actionsets'
import CRUDForm from '../../containers/CRUDForm'
import { CustomerAPI } from '../../api'
import { RequestService } from '../../services'
import { push } from 'connected-react-router'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { roleCheck } from '../../utils'
import { ModelStatus } from '../../constants'
import { LabeledSelect } from 'components'


export class CustomerForm extends CRUDForm{

  static propTypes = {
    dispatch: PropTypes.func,
    match: PropTypes.object.isRequired,
    onSubmitted: PropTypes.func,
    onCancel: PropTypes.func,
  }

  static styles = {
    fields: {
      marginBottom: 20,
      marginTop: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    actions: {
      float: 'right'
    }
  }

  constructor(props){
    super(props)
    this.state = {
      errors: {},
      submissionErrors: [],
      businessName: '',
      businessAddress: '',
      postalAddress: '',
      email: '',
      phone: '',
      additionalNotes: '',
      status: ModelStatus.default,
    }
    CustomerActions.bindActions(this)
  }


  loadCreateItem = () => {
    if(this.createMode && !roleCheck(this.props.role, 'admin')){
      this.props.dispatch(push('/'))
    }
  }

  loadEditItem = async () => {
    if(this.userId !== this.editId && !roleCheck(this.props.role, 'admin')){
      this.props.dispatch(push('/'))
      return
    }
    try{
      const { body: { data: { attributes: { businessName, businessAddress, postalAddress, email, phone, additionalNotes, status }}}} = await CustomerAPI.show(this.editId)(RequestService)
      this.setState({
        businessName, businessAddress, postalAddress, email, phone, additionalNotes, status
      })
    }catch(err){
      this.setState({ submissionErrors: [{ detail: `Couldn't find customer with id ${this.editId}`, source: { pointer:'' }}] })
    }
  }

  submit = async () => {
    if(!this.validate())
      return
    const { businessName, businessAddress, postalAddress, email, phone, additionalNotes, status } = {...this.state }

    const params = { businessName, businessAddress, postalAddress, email, phone, additionalNotes, status }


    let action
    if(this.editMode){
      action = this.actions.updateCustomer(this.editId, params)
    }else{
      action = this.actions.createCustomer(params)
    }

    try{
      await action
      this.props.onSubmitted && this.props.onSubmitted()
    }catch(err){
      const { body: { errors: submissionErrors }} = err
      this.setState({ submissionErrors })
    }
  }

  validate = () => {
    let requiredFields = ['businessName', 'businessAddress', 'status', 'postalAddress', 'email']
    if(this.createMode){
      requiredFields = requiredFields.concat(['phone'])
    }
    const errors = this.requireFields(requiredFields)
    this.setState({ errors })
    return Object.keys(errors).length === 0
  }

  get editId(){
    return this.props.editId || this.props.match.params.editId
  }

  get userId(){
    return this.props.user.id
  }

  get customerId(){
    return this.props.customer.id
  }

  render = () =>
    <div>
      <div style={CustomerForm.styles.fields}>
        {this.renderSubmissionErrors()}


        <TextField
          style={{width: '100%'}}
          label="Business Name"
          value={this.state.businessName}
          onChange={this.onInputValueChange('businessName')}
          {...this.fieldErrors('businessName')}
        />

        <div style={{display: 'flex'}}>

          <TextField
            style={{flex: 1, marginRight: 10}}
            label="Business Address"
            value={this.state.businessAddress}
            onChange={this.onInputValueChange('businessAddress')}
            {...this.fieldErrors('businessAddress')}
            disabled={!roleCheck(this.props.role, 'admin')}
          />

          <TextField
            style={{flex: 1, marginLeft: 10}}
            label="Postal Address"
            value={this.state.postalAddress}
            onChange={this.onInputValueChange('postalAddress')}
            {...this.fieldErrors('postalAddress')}
            disabled={!roleCheck(this.props.role, 'admin')}
          />

        </div>

        <div style={{display: 'flex'}}>

          <TextField
            style={{flex: 1, marginRight: 10}}
            label="Email"
            value={this.state.email}
            onChange={this.onInputValueChange('email')}
            {...this.fieldErrors('email')}
            disabled={!roleCheck(this.props.role, 'admin')}
          />

          <TextField
            style={{flex: 1, marginLeft: 10}}
            label="Phone"
            value={this.state.phone}
            onChange={this.onInputValueChange('phone')}
            {...this.fieldErrors('phone')}
            disabled={!roleCheck(this.props.role, 'admin')}
          />

        </div>

        <div style={{display: 'flex'}}>

          <TextField
            style={{flex: 1, marginRight: 10}}
            label="Additional Notes"
            value={this.state.additionalNotes || ''}
            onChange={this.onInputValueChange('additionalNotes')}
            {...this.fieldErrors('additionalNotes')}
            disabled={!roleCheck(this.props.role, 'admin')}
          />

          <LabeledSelect
           style={{flex: 1, marginLeft: 10}}
           label="Status"
           value={this.state.status}
           onChange={this.onInputValueChange('status')}
           {...this.fieldErrors('status')}
           disabled={!roleCheck(this.props.role, 'admin')}
          >
            <MenuItem value='active' children='Active' />
            <MenuItem value='archived' children='Archived' />
          </LabeledSelect>

        </div>

      </div>
      <div style={CustomerForm.styles.actions}>
        <Button children="Cancel" style={{marginRight: 10}} onClick={this.props.onCancel} />
        <Button variant="contained" color="primary" children='Save' onClick={this.submit} />
      </div>
    </div>
}

export default connect(state => { return {...state.session} })(CustomerForm)
