import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { ProjectTaskActions } from '../../actionsets'
import { ProjectForm } from '.'
import { ProjectUsers, ProjectTasks } from '../'
import { connect } from "react-redux";
import { TitleBar } from 'components'

export class ProjectManagement extends Component {

  constructor(props) {
    super(props)
    ProjectTaskActions.bindActions(this)
    this.state = { activeTab: 0 }
  }

  static propTypes = {
    customers: PropTypes.array.isRequired,
    dispatch: PropTypes.func,
    project: PropTypes.object,
    onProjectUpdate: PropTypes.func
  }

  onProjectSubmitted = () => {
    // refresh status of tasks
    if (this.props.project)
      this.props.onProjectUpdate()
    this.actions.searchProjectTasks({ projectId: this.projectId })
  }

  get projectId() {
    return this.props.project.Id
  }

  renderChild = (i, children) => children[i]

  render = () => (
    <Paper style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <TitleBar>{'Update Project - ' + this.props.project.attributes.name}</TitleBar>
      <Card style={{ padding: 10 }}>

        <Tabs onChange={(e, activeTab) => this.setState({ activeTab })} value={this.state.activeTab} indicatorColor="primary" centered>
          <Tab label="Details" />
          <Tab label="Assigned Users" />
          <Tab label="Tasks" />
        </Tabs>
        <div>
          <TabContent activeTab={this.state.activeTab}>
            <div style={{ padding: 15 }}>
              <ProjectForm {...this.props} project={this.props.project} onSubmitted={this.onProjectSubmitted} onCancel={this.props.history.goBack} />
            </div>
            <ProjectUsers {...this.props} projectId={this.props.project.id} />
            <ProjectTasks {...this.props} projectId={this.props.project.id} />
          </TabContent>
        </div>

      </Card>
    </Paper>
  )
}

const TabContent = ({ activeTab, children }) => children[activeTab]

export default connect(state => { return { ...state.customers, ...state.session } })(ProjectManagement)
