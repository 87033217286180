/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Motorcycle from '@mui/icons-material/DirectionsBike'
import Autorenew from '@mui/icons-material/Autorenew'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { OAuthActions } from '../../actionsets'
import { titleize } from '../../utils'
import jiraLogo from '../../images/jira.png'
import { TitleBar } from '../../components'

const MAX_FILE_UPLOAD_SIZE_MB = 10

export class OAuth extends Component {

  static propTypes = {
    dispatch: PropTypes.func,
    errors: PropTypes.object,
    services: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  static styles = {
    paper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    toolbar: {
      minHeight: 50,
      backgroundColor: '#E8E8E8',
    },
    serviceList: {
      flex: 1,
      padding: 10,
    },
    serviceHeader: {
      textStyle: {
        paddingRight: 0
      }
    }
  }

  constructor(props) {
    super(props)
    OAuthActions.bindActions(this)
  }

  componentDidMount = () => {
    this.refreshServices()
  }

  refreshServices = async () => {
    const { location: { query: { oauth_verifier: oauthVerifier, service } } } = this.props

    await this.actions.getServices()

    if (service && oauthVerifier) {
      await this.actions.verifyService(service, oauthVerifier)
    }
  }

  handleAuthenticateService = serviceName => () => this.actions.authenticateService(serviceName)
  handleDeactivateService = serviceName => () => this.actions.deactivateService(serviceName)
  handleSyncService = serviceName => () => this.actions.syncService(serviceName)
  handleImportService = serviceName => () => {
    const file = this[`import-${serviceName}`].files[0]

    if (file.size > MAX_FILE_UPLOAD_SIZE_MB * 1024 * 1024) {
      this.actions.alert(`File size should not exceed ${MAX_FILE_UPLOAD_SIZE_MB} MB`)
      return
    }

    const formData = new FormData()
    formData.append('service', serviceName)
    formData.append('file', file, file.filename)
    this.actions.importService(serviceName, formData)
  }

  renderServiceButtons = ({ active, requestPending, name }) => {
    const buttonStyle = { borderRadius: 0, margin: 0 }
    if (requestPending) return <i className='fa fa-cog fa-2x fa-spin' />
    if (active) return [
      <input key={`import-${name}-input`} id={`import-${name}`} type='file' accept='.xml'
        onChange={this.handleImportService(name)} style={{ display: 'none' }} />,
      <label key={`import-${name}-button`} htmlFor={`import-${name}`}>
        <Button variant="contained"
          style={{ ...buttonStyle, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
          color="gray"
        >
          Import XML
        </Button>
      </label>,
      <Button
        style={buttonStyle}
        key={`sync-${name}`}
        variant="contained"
        onClick={this.handleSyncService(name)}
        startIcon={<Autorenew />}
        color="white"
      >
        Sync
      </Button>,
      <Button
        style={{ ...buttonStyle, borderTopRightRadius: 4, borderBottomRightRadius: 4 }}
        key={`deactivate-${name}`}
        color="secondary"
        variant="contained"
        onClick={this.handleDeactivateService(name)}
      >Deactivate</Button>
    ]
    if (!active) return (
      <Button
        key={`activate-${name}`}
        color="primary"
        variant="contained"
        onClick={this.handleAuthenticateService(name)}
      >
        Activate
      </Button>
    )
  }

  renderService = service => (
    <Card key={`service-${service.name}`} style={{ display: 'flex', marginBottom: 10 }}>
      <CardHeader
        title={titleize(service.name)}
        subheader={service.active ? 'Active' : 'Inactive'}
        avatar={service.name === 'jira' ? <Avatar src={jiraLogo} style={{ display: 'inline-block' }} /> : <Motorcycle />}
        style={{ flexBasis: '100%' }}
      />
      <CardContent>
        <span className="pull-right">
          {this.renderServiceButtons(service)}
        </span>
      </CardContent>
    </Card>

  )

  formatServices = services => (
    Object.keys(services).map(service => ({
      name: service,
      ...services[service].attributes
    }))
  )

  render = () => (
    <div style={OAuth.styles.paper}>
      <TitleBar>Linked Services</TitleBar>
      <div style={OAuth.styles.serviceList} >
        {this.formatServices(this.props.services).map(this.renderService)}
      </div>
    </div>
  )
}

export const mapStateToProps = ({ oauth, router: { location } }) => ({ ...oauth, location })

export default connect(mapStateToProps)(OAuth)
