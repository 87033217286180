import { ProjectTaskAPI } from 'api'
import { RequestService } from 'services'
import { SimpleAutocomplete } from 'components'
import { chopString, debounce } from 'utils'
import { connect } from 'react-redux'

const ProjectTaskAutoComplete = ({ recentProjectTasks, includeClosedTasks, dispatch, ...props }) => {
  const fetchOptions = debounce(async (term) => {
    try {
      if (term && term.length > 1) {
        let { body: { data: projectTasks } } = await ProjectTaskAPI.autocomplete({ term, includeClosedTasks })(RequestService)
        return projectTasks
      }
    } catch (ex) {
      console.error(ex)
    }
    return []
  })

  return <SimpleAutocomplete
    {...props}
    renderOption={renderOption}
    renderText={renderText}
    fetchOptions={fetchOptions}
    defaultOptions={recentProjectTasks}
  />
}

const renderText = (option) => {
  return option ? `${optionCode(option)} ${option?.attributes?.name}` : ''
}

const renderOption = (option) => <>
  <div style={{ flex: '1 1 auto' }}>{chopString(option?.attributes?.name || '', 40)}</div>
  <div style={{ marginLeft: 'auto' }}>{optionCode(option)}</div>
</>

const optionCode = (option) => (option?.attributes?.code || option?.attributes?.projectCode || '')

export default connect(({ projectTasks: { recentProjectTasks } }) => ({ recentProjectTasks }))(ProjectTaskAutoComplete)