import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { ListView, ListViewColumn } from '../../components'
import Button from '@mui/material/Button'
import { ItemRenderers, ItemSorters } from '../../components/List'
import { CustomerActions } from '../../actionsets'
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper'
import Dialog from '../Dialog'
import { FilterTypes } from '../../constants'
import { FilterBar } from '../FilterBar'
import { CustomerForm } from '.'
import { connect } from "react-redux"
import { TitleBar } from 'components'

export class Customers extends Component {

  static propTypes = {
    dispatch: PropTypes.func,
    customers: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      selected: null,
      filterState: FilterTypes.ACTIVE
    }
    CustomerActions.bindActions(this)
  }

  componentDidMount = () => {
    this.loadCustomers()
  }

  loadCustomers = (query = this.filterToStatus(this.state.filterState)) => {
    this.actions.searchCustomers(query)
  }

  customerSelected = (selected) => {
    this.setState({ selected })
  }

  onFormSubmitted = success => {
    if (success) this.loadCustomers()
    this.setState({ selected: null })
  }

  filterToStatus = filter => filter !== FilterTypes.ALL ? { status: filter.toLowerCase() } : {}

  onFilterChange = (filter) => {
    this.setState({ filterState: filter })
    this.loadCustomers(this.filterToStatus(filter))
  }

  renderButtons = () => <>
    <Button variant="contained" color="primary" style={{ float: 'right', marginLeft: 10 }} children='New' onClick={() => this.setState({ selected: {} })} />
    <FilterBar filter={this.state.filterState} onFilterChange={this.onFilterChange} />
  </>

  render = () =>
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <TitleBar>Customers</TitleBar>
      <div style={{ padding: 10 }}>
        <Dialog
          title={this.state.selected && this.state.selected.attributes ? 'Update Customer - ' + this.state.selected.attributes.businessName : 'New Customer'}
          open={!!this.state.selected}
          fullWidth
          maxWidth="md"
        >
          <CustomerForm
            {...this.props}
            editId={this.state.selected && this.state.selected.id}
            onSubmitted={() => this.onFormSubmitted(true)}
            onCancel={() => this.onFormSubmitted(false)}
          />
        </Dialog>

        <ListView
          pagination={{ pageSize: 10 }}
          items={this.props.customers}
          sortable={true}
          defaultSortBy='businessName'
          defaultSortOrder='asc'
          filtering={true}
          onRowClick={this.customerSelected}
          filterActions={this.renderButtons()}
        >

          <ListViewColumn name='Name' attribute='businessName' itemSorter={ItemSorters.stringPlainItemSorter} />
          <ListViewColumn name='Phone' attribute='phone' itemSorter={ItemSorters.stringPlainItemSorter} />
          <ListViewColumn name='Email' attribute='email' itemSorter={ItemSorters.stringPlainItemSorter} />
          <ListViewColumn name='Status' attribute='status' itemRenderer={ItemRenderers.titleizedItemRenderer} />
        </ListView>
      </div>
    </div>
}

export default connect(({ customers }) => customers)(Customers)
