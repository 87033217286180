import React, {  } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import moment from 'moment'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CRUDForm from '../../../containers/CRUDForm'
import { TimesheetEntryActions, ProjectTaskActions } from '../../../actionsets'
import { ProjectTaskAutoComplete } from '../'
import { formatDate, roundMsToQuarterHours, elapsedTime } from '../../../utils'
import { TimerStore } from '../../../services'
import { ModelStatus } from '../../../constants'


const FORM_KEY = 'form'
const AUTOSAVE = 5000 // save to local storage after AUTOSAVE ms

export class TimerForm extends CRUDForm{

  static propTypes = {
    time: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  static styles = {
    fields: {
      flex: 1,
      display: 'flex',
    },

    actions: {
      flex: 0,
      minWidth: 110,
      marginLeft: 20,
      paddingTop: 30,
      paddingRight: 30,
      position: 'relative',
    },

    close: {
      position: 'absolute',
      top: 5,
      right: 5,
    },

    timeField: {
      flex: 0,
      minWidth: 65,
      marginRight: 10,
      marginTop: 20,
    },

    taskField: {
      flex: 1,
      marginRight: 10,
      width: 0,
      marginTop: 20,
    },

    commentField: {
      flex: 1,
      width: 0,
      marginTop: 20,
    },

    menuItem: {
      fontSize: 14,
    },
  }

  static initialState = {
    errors: {},
    submissionErrors: [],
    projectTaskId: '',
    comment: '',
  }

  constructor(props){
    super(props)
    TimesheetEntryActions.bindActions(this)
    ProjectTaskActions.bindActions(this)
    this.state = TimerStore.get(FORM_KEY) || TimerForm.initialState
  }

  componentDidMount = () => {
    this.actions.searchTimerProjectTasks({userId: this.props.user.id, status: ModelStatus.active}, {createdAt: 'DESC', projectId: 'DESC'})
  }

  get newTimesheetEntry(){
    const { comment, projectTaskId } = this.state
    const { time, user: {id} } = this.props
    return {id: null, attributes: {userId: id, date: formatDate(moment()), time: roundMsToQuarterHours(time), projectTaskId, comment}}
  }

  resetForm = () => {
    this.autoSave(TimerForm.initialState)
    this.setState(TimerForm.initialState)
  }

  submit = async () => {
    if (!this.validate()) return

    try {
      await this.actions.createTimesheetEntry(this.newTimesheetEntry)
      this.resetForm()
      this.props.onSubmitted && this.props.onSubmitted()
    } catch(err){
      const { body: { errors: submissionErrors }} = err
      this.setState({ submissionErrors })
    }
  }

  validate = () => {
    let requiredFields = ['comment', 'projectTaskId']
    const errors = this.requireFields(requiredFields)
    this.setState({ errors })
    return Object.keys(errors).length === 0
  }

  onFieldChange = (fieldName, value) => {
    this.setState({[fieldName]: value})
    this.autoSave()
  }

  autoSave = (params=null) => {
    const save = state => {
      clearTimeout(this.timer)
      this.timer = null
      TimerStore.set(FORM_KEY, state)
    }

    if (params) {
      save(params)
    } else {
      if (!this.timer) {
        this.timer = setTimeout(() => save(this.state), AUTOSAVE)
      }
    }
  }

  handleProjectTaskChange = (projectTask) => {
    this.setState({ projectTask, projectTaskId: projectTask?.id })
    this.autoSave()
  }

  render = () =>
    <div style={TimerForm.styles.fields}>

      <TextField
        label="Time"
        value={elapsedTime(this.props.time)}
        error={!!this.state.errors.time}
        helperText={this.state.errors.time}
        style={TimerForm.styles.timeField}
        disabled
      />

      <ProjectTaskAutoComplete
        label='Task'
        value={this.state.projectTask}
        errorText={this.state.errors.projectTaskId}
        onChange={this.handleProjectTaskChange}
        style={TimerForm.styles.taskField}
      />

      <TextField
        label="Comment"
        value={this.state.comment}
        onChange={e => this.onFieldChange('comment', e.target.value)}
        error={!!this.state.errors.comment}
        helperText={this.state.errors.comment}
        style={TimerForm.styles.commentField}
      />
      <div style={TimerForm.styles.actions}>

        <IconButton
          onClick={this.props.onCancel}
          style={TimerForm.styles.close}
        >
          <CloseIcon />
        </IconButton>
        <Button onClick={this.submit} variant="contained">Save</Button>
      </div>

    </div>

}


export default connect(state => ({...state.session, projectTasks: state.projectTasks.timerProjectTasks}))(TimerForm)

