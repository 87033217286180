import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { ListView, ListViewColumn } from '../../components'
import { ItemRenderers, ItemSorters } from '../../components/List'
import { ProjectUserActions, UserActions } from '../../actionsets'
import Dialog from '../Dialog'
import { ProjectUserForm } from '.'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import { connect } from "react-redux"
import {ModelStatus} from "../../constants"
import {sortByName} from "../../utils"


export class ProjectUsers extends Component{

  static propTypes = {
    dispatch: PropTypes.func,
    projectUsers: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    projectId: PropTypes.string.isRequired,
  }

  constructor(props){
    super(props)
    ProjectUserActions.bindActions(this)
    UserActions.bindActions(this)

    this.state = {selected: null}
  }

  componentDidMount = () => {
    this.loadProjectUsers()
    this.actions.loadUsers()
  }

  loadProjectUsers = () => {
    this.actions.searchProjectUsers({projectId: this.props.projectId})
  }

  projectUserSelected = (selected) => {
    this.setState({selected})
  }

  onFormSubmitted = success => {
    if (success) this.loadProjectUsers()
    this.setState({selected: null})
  }

  onProjectUserDelete = async projectUser => {
    await this.actions.deleteProjectUser(projectUser.id)
    this.loadProjectUsers()
  }

  // users that are not yet associated with the project
  userOptionsForSelected = selected => {
    let users = this.props.users.filter(
      usr => !this.props.projectUsers.find(prj => prj.attributes.userId + '' === usr.id + '')
        && usr.attributes.status === ModelStatus.active
    )
    if (selected.attributes) {
      let current = this.props.users.find(usr => selected.attributes.userId+'' === usr.id+'')
      users.push(current)
    }

    return sortByName(users)
  }

  renderDeleteAction = projectUser => (
    <Button
      color="primary"
      variant="text"
      onClick={event => { event.stopPropagation(); this.onProjectUserDelete(projectUser) }}
      style={{minWidth: 60, padding: 0, margin: 0}}
    >
      <DeleteIcon fontSize="small"/>
    </Button>
  )

  renderButtons = () => <>
    <Button
      color="primary"
      variant="contained"
      children='New'
      style={{float: 'right'}}
      onClick={() => this.projectUserSelected({})}
    />
  </>

  render = () =>
    <div>
      <Dialog
        title="Assign User"
        open={!!this.state.selected}
        fullWidth
        maxWidth="md"
      >
        <ProjectUserForm
          {...this.props}
          editId={this.state.selected && this.state.selected.id}
          users={this.state.selected && this.userOptionsForSelected(this.state.selected)}
          projectId={this.props.projectId}
          onSubmitted={() => this.onFormSubmitted(true)}
          onCancel={() => this.onFormSubmitted(false)}
        />
      </Dialog>

      <ListView
        pagination={{pageSize: 10}}
        items={this.props.projectUsers}
        sortable={true}
        defaultSortBy='projectName'
        defaultSortOrder='asc'
        filtering={true}
        onRowClick={this.projectUserSelected}
        filterActions={this.renderButtons()}
      >
        <ListViewColumn name='Project' attribute='projectName' itemSorter={ItemSorters.stringPlainItemSorter}/>
        <ListViewColumn name='User'    attribute='userName' itemSorter={ItemSorters.stringPlainItemSorter}/>
        <ListViewColumn name='Role'    attribute='role' itemRenderer={ItemRenderers.titleizedItemRenderer} />
        <ListViewColumn name='' itemRenderer={this.renderDeleteAction} />
      </ListView>
    </div>

}

export default connect(({projectUsers, user}) => {return {...projectUsers, ...user}})(ProjectUsers)

