import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { CenteredCard } from '../components'
import { SessionActions } from '../actionsets'


export class SetPassword extends Component {

  static propTypes = {
    errors: PropTypes.object.isRequired
  }

  constructor(props){
    super(props)
    this.state = {
      password: '',
      passwordConfirmation: '',
    }
    SessionActions.bindActions(this)
  }

  componentDidMount = () => {
    if(this._emailInput) this._emailInput.focus()
    this.setState({token: window.location.hash.slice(1)})
  }

  setPassword = ({ target: { value }}) => this.setState({password: value})

  setPasswordConfirmation = ({ target: { value }}) => this.setState({passwordConfirmation: value})

  keyPressed = ({which}) => which === 13 && this.savePassword()

  savePassword = () => {
    const { token, password, passwordConfirmation } = this.state
    this.actions.setPassword({token, password, passwordConfirmation})
  }

  setPasswordError = () =>
    this.props.errors[SessionActions.SET_PASSWORD][0].detail || this.props.errors[SessionActions.SET_PASSWORD][0].title

  errorText = () => this.props.errors[SessionActions.SET_PASSWORD] ?
                        this.setPasswordError() :
                        null

  render = () =>
    <CenteredCard>
      <Card onKeyUp={this.keyPressed}>
        <CardHeader title={
          <Typography variant="h1" style={{color: 'white', fontSize: '24px', fontWeight: '400'}}>Set your password</Typography>
        } style={{ backgroundColor: 'var(--primary-color)' }} />
        <CardContent>
           <TextField fullWidth ref={ref => this._emailInput = ref} label="Password" type="password" onChange={this.setPassword} style={{ width: 256 }}/>
           <br/>
           <TextField fullWidth label="Password Confirmation" type="password" onChange={this.setPasswordConfirmation} style={{ width: 256 }} helperText={this.errorText()} error={!!this.errorText()}/>
        </CardContent>
        <CardActions>
          <Link to='/sessions/sign_in'>Sign In</Link>
          <Button color="primary" variant="contained" style={{float: 'right', marginBottom: 0}} onClick={this.savePassword}>
            Set Password
          </Button>
          <br style={{clear: 'both'}}/>
        </CardActions>
      </Card>
    </CenteredCard>
}

export default connect(({session}) => session)(SetPassword)
