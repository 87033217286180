import React from 'react'
import MuiDialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Provider } from "react-redux";
import store from "../store";

export default function Dialog(props) {
  const { children, title, ...restOfProps } = props
  return (
    <MuiDialog {...restOfProps}>
      <DialogTitle>{title}</DialogTitle>
      {/*
        We wrap Dialog content with the store Provider since react-redux needs
        store to be in context in order for connect() to work correctly,
        but @material-ui Dialog renders Dialogs outside of the main component tree.
      */}
      <Provider store={store}>
        <DialogContent>
          {children}
        </DialogContent>
      </Provider>
    </MuiDialog>
  )
}