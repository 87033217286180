import React, { Component }                from 'react'
import Checkbox                              from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

export class LabeledCheckbox extends Component{

  render = () => {
    const {fullWidth, label, error, helperText, formControlStyle, ...props } = this.props
    return (
      <FormControl fullWidth={!!fullWidth} error={!!error} style={formControlStyle}>
        <FormControlLabel
            label={label}
            control={
              <Checkbox checked={`${props.value}`==='true'} type='checkbox' {...props}/>
            }
          />
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

}

export default LabeledCheckbox