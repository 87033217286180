import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { theme } from '../styles/theme'
import { connect } from 'react-redux'

import { ThemeProvider } from '@mui/material/styles';

export class VitalstatistixThemeProvider extends Component{
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    fontFamily: PropTypes.string,
    primaryColor: PropTypes.string
  }

  render = () =>
    <ThemeProvider theme={theme}>
      {this.props.children}
    </ThemeProvider>
}

class ThemedComponent extends Component{

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    component: PropTypes.func,
    fontFamily: PropTypes.string,
    primaryColor: PropTypes.string,
    user: PropTypes.object
  }

  render = () => {
    const { component: Component, user, children, ...props } = this.props
    const { fontFamily, primaryColor } = user.attributes
    return (
      <Component fontFamily={fontFamily} primaryColor={primaryColor} {...props}>
        {children}
      </Component>
    )
  }
}

const ConnectedThemedComponent = connect(({session : { user }}) => {
  return {user}
})(ThemedComponent)

export const Themed = component => {
  const WrappedThemed = props => <ConnectedThemedComponent component={component} {...props}>{props.children}</ConnectedThemedComponent>
  WrappedThemed.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  }
  return WrappedThemed
}

export default connect(({session: { user: { attributes } }}) => attributes)(VitalstatistixThemeProvider)