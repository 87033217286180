import React, { Component, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import Button from '@mui/material/Button'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { dateKey } from '../../../utils'
import { DatePicker } from '../../../components'
import Toolbar from '@mui/material/Toolbar'
import FontAwesome from 'react-fontawesome'
import { TimesheetEntryActions } from '../../../actionsets'
import moment from 'moment'

export default class TimesheetCalendarToolbar extends Component {
  state = {}
  constructor(props) {
    super(props)
    TimesheetEntryActions.bindActions(this)
  }

  static propTypes = {
    dates: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    navLeftBy: PropTypes.object.isRequired,
    navRightBy: PropTypes.object.isRequired,
    selectedDate: PropTypes.object,
    highlightedDate: PropTypes.object
  }

  static styles = {

    toolbar: {
      display: 'flex',
      height: 'auto',
      flexWrap: 'wrap-reverse',
      padding: 0,
      margin: 0,
      backgroundColor: 'rgb(232, 232, 232)',
      justifyContent: 'space-between',
      alignItems: 'stretch'
    },

    dateButtonGroup: {
      marginLeft: 24,
      flexWrap: 'wrap',
      justifyContent: 'flex-start'
    },

    datePickerGroup: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 32
    },

    datePicker: {
      padding: 0,
      margin: 0,
      backgroundColor: 'var(--primary-color)',
      color: '#FFFFFF',
      paddingRight: 20,
      paddingLeft: 20,
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
      borderRadius: '2px'
    },

    datePickerLabel: {
      width: 100,
      top: 5,
      paddingLeft: 20,
      cursor: 'pointer',
      textTransform: 'uppercase',
      fontSize: 14,
    },

    dateButton: {
      flexShrink: 1,
      width: 100,
      margin: '10px 10px 10px 0px',
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.6)',
    },

    navButton: {
      minWidth: 30,
      margin: '10px 10px 10px 0px',
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.6)',
    },

    navButtonLabel: {
      padding: 0,
      width: 12,
    },

    dateButtonLabel: {
      padding: 0,
    },

    dateSelected: {
      flexShrink: 1,
      width: 100,
      margin: '10px 10px 10px 0px',
      backgroundColor: 'var(--primary-color)',
      color: '#fff'
    },

    dateHighlighted: {
      flexShrink: 1,
      width: 100,
      margin: '10px 10px 10px 0px',
      backgroundColor: '#9FA8DA',
      color: '#fff',
    },

    dateMissingTime: {
      flexShrink: 1,
      width: 100,
      margin: '10px 10px 10px 0px',
      backgroundColor: '#FF6347',
      color: '#fff',
    },

    dateMissingTimeSelected: {
      flexShrink: 1,
      width: 100,
      margin: '10px 10px 10px 0px',
      backgroundColor: '#F00',
      color: '#fff',
    },

    dialogContent: {
      marginTop: 10
    }
  }

  handleDateButtonDrag = (copyFromDate) => (e) => {
    e.dataTransfer.setData("copyFromDate", copyFromDate);
  }

  handleDateButtonDrop = (copyToDate) => (e) => {
    const copyFromDate = e.dataTransfer.getData("copyFromDate")
    this.setState({ copyDialogOpen: true, copyFromDate, copyToDate })
  }

  renderDateButton = date => {
    const key = dateKey(date)
    const { selectedDate, missingTimeByDate, highlightedDate } = this.props
    let style = date.isSame(highlightedDate, 'day') ? TimesheetCalendarToolbar.styles.dateHighlighted : TimesheetCalendarToolbar.styles.dateButton
    const dateSelected = date.isSame(selectedDate, 'day')
    if (missingTimeByDate[key]) {
      style = dateSelected ? TimesheetCalendarToolbar.styles.dateMissingTimeSelected : TimesheetCalendarToolbar.styles.dateMissingTime
    }
    else if (dateSelected) {
      style = TimesheetCalendarToolbar.styles.dateSelected
    }
    return <Button
      key={key}
      children={date.format('ddd Do')}
      onClick={() => this.props.onChange(date)}
      style={style}
      draggable="true"
      date={date}
      onDragStart={this.handleDateButtonDrag(date.format('YYYY-MM-DD'))}
      onDrop={this.handleDateButtonDrop(date.format('YYYY-MM-DD'))}
      onDragOver={e => e.preventDefault()}
    />
  }

  renderNavButton = (icon, onChange) =>
    <Button onClick={onChange} style={TimesheetCalendarToolbar.styles.navButton}>
      <FontAwesome title="navigate" name={icon} fixedWidth style={TimesheetCalendarToolbar.styles.navButtonLabel} />&#8203;
    </Button>

  handleCopyTimesheetEntriesConfirm = (fromDate, toDate) => {
    this.setState({ copyDialogOpen: false, copyFromDate: undefined, copyToDate: undefined })
    this.props.copyTimesheetEntries(moment(fromDate).format('YYYY-MM-DD'), moment(toDate).format('YYYY-MM-DD'))
  }

  openDatePicker = () => this.setState({ chooseDateOpen: true })
  closeDatePicker = () => this.setState({ chooseDateOpen: false })

  render = () => {
    const { dates, navLeftBy, navRightBy } = this.props

    return (
      <React.Fragment>
        <Toolbar style={TimesheetCalendarToolbar.styles.toolbar}>

          <div style={TimesheetCalendarToolbar.styles.dateButtonGroup}>

            {this.renderNavButton('chevron-left', () => this.props.onChange(moment(dates[0]).subtract(navLeftBy.offset, navLeftBy.factor)))}

            {dates.map(this.renderDateButton)}

            {this.renderNavButton('chevron-right', () => this.props.onChange(moment(dates[dates.length - 1]).add(navRightBy.offset, navRightBy.factor)))}

          </div>

          <div style={TimesheetCalendarToolbar.styles.datePickerGroup}>
            <Button onClick={this.openDatePicker} variant="contained" style={{ marginBottom: 10, marginTop: 10, marginLeft: 25 }}>
              Select Date
            </Button>
            <DatePicker
              value={null}
              open={!!this.state.chooseDateOpen}
              onOpen={this.openDatePicker}
              onClose={this.closeDatePicker}
              onChange={({ target: { value } }) => this.props.onChange(moment(value))}
              autoOk={true}
              css={{ position: 'absolute', visibility: 'hidden', bottom: 0, left: 0 }}
            />
          </div>

        </Toolbar>
        <CopyTimesheetEntriesDialog
          open={this.state.copyDialogOpen}
          copyFromDate={this.state.copyFromDate}
          copyToDate={this.state.copyToDate}
          onClose={() => this.setState({ copyDialogOpen: false, copyFromDate: undefined, copyToDate: undefined })}
          onConfirm={this.handleCopyTimesheetEntriesConfirm}
        />
      </React.Fragment>
    )
  }

}

const CopyTimesheetEntriesDialog = ({ copyFromDate, copyToDate, open, onClose, onConfirm }) => {
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()

  const prevOpenRef = useRef();
  useEffect(() => {
    if (open && !prevOpenRef.current && copyFromDate && copyToDate) {
      setFromDate(new Date(copyFromDate))
      setToDate(new Date(copyToDate))
    }
    prevOpenRef.current = open
  }, [copyFromDate, copyToDate, open])

  return (
    <Dialog open={!!open} contentStyle={{ maxWidth: '300px' }}>
      <DialogTitle>Copy Timesheet Entries</DialogTitle>
      <DialogContent>
        <div style={TimesheetCalendarToolbar.styles.dialogContent}>
          <DatePicker label="From Date" fullWidth value={fromDate} onChange={({ target: { value } }) => setFromDate(value)} />
        </div>
        <div><DatePicker label="To Date" fullWidth value={toDate} onChange={({ target: { value } }) => setToDate(value)} /></div>
      </DialogContent>
      <DialogActions>
        <Button children="Cancel" onClick={() => (onClose && onClose())} />
        <Button children="Confirm" variant="contained" color="primary" onClick={() => { onConfirm && onConfirm(fromDate, toDate) }} />
      </DialogActions>
    </Dialog>
  )
}