import React, {  } from 'react'
import PropTypes from 'prop-types';
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader';

export default function SectionWrapper ({ children, title }) {
  const noContentHeader = <ListSubheader>No {title} items</ListSubheader>

  return (
    <List>
      { children.length
        ? [<ListSubheader key={`${title}-subheader`}>{title}</ListSubheader>, ...children]
        : noContentHeader
      }
    </List>
  )
}

SectionWrapper.propTypes = {
  children: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}
