/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Table from '@mui/material/Table'
import Checkbox from '@mui/material/Checkbox'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Pagination } from '../'
import TextField from '@mui/material/TextField';
import ActionSearch from '@mui/icons-material/Search';
import * as ItemMatchStringProviders from './ItemMatchStringProviders'
import { CustomPropTypes } from '../../utils'
import ListViewColumn from './ListViewColumn'
import { Card, CardContent } from '@mui/material';

export class ListView extends Component {

  static propTypes = {
    items: PropTypes.array.isRequired,
    children: CustomPropTypes.arrayOfElementType(ListViewColumn).isRequired,
    enableSelectAll: PropTypes.bool.isRequired,
    sortable: PropTypes.bool.isRequired,
    showRowHover: PropTypes.bool.isRequired,
    allRowsSelected: PropTypes.bool,
    onRowClick: PropTypes.func,
    onSelectionChanged: PropTypes.func,
    isRowSelectedTest: PropTypes.func,
    defaultSortBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultSortOrder: PropTypes.string,
    height: PropTypes.string,
    pagination: PropTypes.oneOfType([
      PropTypes.shape({
        currentPage: PropTypes.number,
        totalPages: PropTypes.number,
        onPageSelected: PropTypes.func
      }),
      PropTypes.shape({
        pageSize: PropTypes.number
      })
    ]),
    sorting: PropTypes.shape({
      sortBy: PropTypes.string,
      sortDirection: PropTypes.oneOf(['asc', 'desc']),
      onSortChange: PropTypes.func
    }),
    filtering: PropTypes.oneOfType([
      PropTypes.shape({
        filterMatchStringProvider: PropTypes.func
      }),
      PropTypes.bool,
      PropTypes.shape({
        onFilterChange: PropTypes.func,
        filterText: PropTypes.string
      })
    ]),
    style: PropTypes.object.isRequired,
    paginationStyle: PropTypes.object.isRequired,
  }

  static defaultProps = {
    style: {},
    paginationStyle: { float: 'right' },
    items: [],
    enableSelectAll: false,
    sortable: false,
    showRowHover: true,
    isRowSelectedTest: () => false
  }

  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      sortBy: props.defaultSortBy || this.columns[0].attribute,
      sortDirection: props.defaultSortOrder || 'asc'
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.items !== prevProps.items && this.props.selectedItems && this.props.selectedItems.length > 0) {
      this.onSelectionChanged([])
    }
  }

  get columns() {
    return React.Children.map(this.props.children, ({ props }) => props)
  }

  handleSelectAllChange = ({ target: { checked } }) => {
    if (checked) {
      this.onSelectionChanged('all')
    } else {
      this.onSelectionChanged('none')
    }
  }

  get selectedItems() {
    return (this.props.selectedItems || [])
  }

  renderHeaderRow = () =>
    <TableRow>
      {this.props.enableSelectAll && <TableCell style={{ width: 1, paddingTop: 0, paddingBottom: 0  }}>
        <Checkbox
          color="primary"
          onChange={this.handleSelectAllChange}
          checked={this.props.items && !!this.props.items.length && this.props.items.every(item => this.isRowSelected(item))} />
      </TableCell>}
      {this.columns.map(this.renderHeaderCell)}
    </TableRow>

  renderHeaderCell = (column, index) => {
    return <TableCell
      onClick={this.onHeaderClick(index)}
      key={index}
      style={{ ...column.style, color: 'gray', cursor: 'pointer', fontSize: '0.8em' }}>
      {this.renderSortArrow(column)}{column.name}
    </TableCell>
  }

  get isSortingControlled() {
    return !!(this.props.sorting)
  }

  get sortBy() {
    return (this.props.sorting && this.props.sorting.sortBy) || this.state.sortBy
  }

  get sortDirection() {
    return (this.props.sorting && this.props.sorting.sortDirection) || this.state.sortDirection
  }

  renderSortArrow = (column) => {
    if (!this.props.sortable)
      return false
    const sortDirection = this.getSortDirection(column)
    switch (sortDirection) {
      case 'asc': { return <span style={{ marginRight: 2 }}>▲</span> }
      case 'desc': { return <span style={{ marginRight: 2 }}>▼</span> }
      default: { return false }
    }
  }

  getSortDirection = (column) => {
    if (this.sortBy === column.attribute) {
      return this.sortDirection
    }
  }

  handleCheckChanged = (item) => ({ target: { checked } }) => {
    if (checked) {
      this.onSelectionChanged([...this.selectedItems.filter(x => x !== item), item])
    } else {
      this.onSelectionChanged(this.selectedItems.filter(x => x !== item))
    }
  }

  onSelectionChanged = (selection) => {
    this.props.onSelectionChanged && this.props.onSelectionChanged(selection)
  }

  isRowSelected = (item) => {
    return this.props.isRowSelectedTest(item)
  }

  renderBodyRow = (item, index) => {
    return <TableRow key={index}>
      {this.props.enableSelectAll && <TableCell style={{ width: 1, paddingTop: 0, paddingBottom: 0 }}>
        <Checkbox color="primary" onChange={this.handleCheckChanged(item)} checked={this.isRowSelected(item)} />
      </TableCell>}
      {this.columns.map(this.renderItemCell(item))}
    </TableRow>
  }

  onRowClick = (item) => () => {
    this.props.onRowClick && this.props.onRowClick(item)
  }

  renderItemCell = (item) => (column, index) => {
    return <TableCell onClick={this.onRowClick(item)} style={{ ...column.style, cursor: 'pointer' }} key={index}>{column.itemRenderer(item, column)}</TableCell>
  }

  onHeaderClick = (column) => () => {
    return this.setSortBy(this.columns[column])
  }

  setSortBy = (column) => {
    if (!(this.props.sortable && column.sortable && column.itemSorter))
      return
    const currentSortDirection = this.getSortDirection(column)
    const nextSortDirection = currentSortDirection === 'asc' ? 'desc' : 'asc'
    if (this.isSortingControlled) {
      this.props.sorting.onSortChange(column.attribute, nextSortDirection)
    } else {
      this.setState({ sortBy: column.attribute, sortDirection: nextSortDirection })
    }
  }

  renderPagination = () =>
    <Pagination
      key='pagination'
      style={this.props.paginationStyle}
      page={this.currentPage}
      totalPages={this.totalPages}
      onPageSelected={this.onPageSelected}
    />

  renderItemCount = () => {
    return (
      <div style={{ float: 'left', padding: '15px' }}>
        {this.itemCountText}
      </div>
    )
  }

  get itemCountText() {
    if (this.itemCountForCurrentPage === 1)
      return `Item ${this.paginationRange[0] + 1} of ${this.items.length}`
    else
      return `Items ${this.paginationRange[0] + 1} to ${Math.min(this.paginationRange[1], this.items.length)} of ${this.items.length}`
  }

  renderTextFilter = () =>
    <div css={{
      padding: 10,
      marginBottom: 16,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap-reverse',
      rowGap: 10,
      '.MuiButton-root': {
        marginBottom: 0
      },
      '@media (max-width: 800px)': {
        '& > div': {
          flex: '1 0 100%',
        }
      }
    }}>
      <div css={{ flex: '1 0 auto' }}>
        <TextField key='filter' defaultValue={this.filterText} onChange={this.filterTextChanged} variant="outlined" size="small"
          css={{ marginBottom: 0, width: 400, '@media (max-width: 800px)': { width: '100%' } }}
          InputProps={{ startAdornment: <ActionSearch style={{ color: 'silver', width: 28, height: 28, marginRight: 10 }} /> }} />
      </div>
      <div style={{ flex: 1 }}></div>
      <div css={{ flex: '1 0 auto' }}>
        {this.props.filterActions}
      </div>
    </div>

  get filterText() {
    return this.isFilteringControlled ? this.props.filtering.filterText : this.state.filterText
  }

  get isFilteringControlled() {
    return !(
      this.props.filtering === true ||
      (this.props.filtering && this.props.filtering.filterMatchStringProvider)
    )
  }

  extractFilterString = (item) => {
    if (this.props.filtering && this.props.filtering.filterMatchStringProvider) {
      return this.props.filtering.filterMatchStringProvider(item, this.columns)
    } else {
      return ItemMatchStringProviders.JSONAPIMatchStringProvider(this.columns, item)
    }
  }

  filterTextChanged = ({ target: { value: filterText } }) =>
    this.isFilteringControlled ? this.props.filtering.onFilterChange(filterText) : this.setState({ filterText })

  onPageSelected = (currentPage) => {
    this.onSelectionChanged([])
    return this.isPaginationControlled ?
      this.props.pagination.onPageSelected(currentPage) :
      this.setState({ currentPage })
  }

  get isPaginated() {
    return !!(this.props.pagination)
  }

  get willShowPagination() {
    return this.isPaginated && (this.items.length > this.props.pagination.pageSize)
  }

  get controlledPaginationParams() {
    return [
      this.props.pagination.currentPage,
      this.props.pagination.onPageSelected,
      this.props.pagination.totalPages
    ]
  }
  get isPaginationControlled() {
    return this.controlledPaginationParams.filter(x => x !== undefined).length === 3
  }

  get currentPage() {
    return this.props.pagination.currentPage || this.state.currentPage
  }

  get totalPages() {
    return this.props.pagination.totalPages || Math.ceil(this.items.length / this.props.pagination.pageSize)
  }

  get paginationRange() {
    const beginning = (Math.min(this.totalPages, this.currentPage) - 1) * this.pageSize
    const end = (Math.min(this.totalPages, this.currentPage)) * this.pageSize
    return [beginning, end]
  }

  get itemCountForCurrentPage() {
    return this.items.length - this.paginationRange[0]
  }

  get pageSize() {
    return this.props.pagination.pageSize
  }

  get paginatedItems() {
    if (this.isPaginated && !this.isPaginationControlled) {
      const [beginning, end] = this.paginationRange
      return this.items.slice(beginning, end)
    } else {
      return this.items
    }
  }

  get sorter() {
    return (a, b) => {
      return this.sortColumn.itemSorter(a, b, this.sortColumn)
    }
  }

  get sortColumn() {
    return this.columns.find(c => c.attribute === this.sortBy)
  }

  get items() {
    let items;
    if (!this.props.sortable || this.isSortingControlled || !this.sortColumn.sortable) {
      items = this.props.items
    } else {
      const sorted = this.props.items.slice().sort(this.sorter)
      items = this.sortDirection === 'desc' ? sorted.reverse() : sorted
    }
    if (this.state.filterText) {
      items = items.filter(item => this.extractFilterString(item).toLowerCase().match(this.state.filterText.toLowerCase()))
    }
    return items
  }

  get showTextFilter() {
    return !!this.props.filtering
  }

  render = () =>
    <div style={this.props.style}>
      {this.showTextFilter && this.renderTextFilter()}
      {/* <br style={{clear: 'both'}}/> */}
      {
        this.isPaginated && this.props.topPagination && this.willShowPagination &&
        <div style={{ overflow: 'hidden', borderBottom: 'solid 1px #e0e0e0' }}>
          {this.isPaginated && this.renderPagination()}
        </div>
      }
      <Table>
        <TableHead>
          {this.renderHeaderRow()}
        </TableHead>
        <TableBody>
          {this.paginatedItems.map(this.renderBodyRow)}
        </TableBody>
      </Table>
      <div style={{ 'paddingTop': '15px' }}>
        {this.isPaginated && this.renderPagination()}
        {this.willShowPagination && this.renderItemCount()}
      </div>
    </div>
}

export default ListView