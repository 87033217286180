import ActionSet from './ActionSet'
import { ProjectTaskAPI } from '../api'
import { SnackBarActions } from '.'
import requestServiceConstantsMiddleware from '../middleware/requestServiceConstantsMiddleware'

export class ProjectTaskActionSet extends ActionSet{

  static initialState = {
    projectTasks: [],
    timerProjectTasks: [],
    requestingProjectTasks: false,
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static loadProjectTasks(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(() => {
      return dispatch =>
        dispatch({
          type: this.LOAD_PROJECT_TASKS,
          api: ProjectTaskAPI.index()
        })
    })

    reducer({
      [this.LOAD_PROJECT_TASKS_REQUEST]: (state) => {
        return {...state, requestingProjectTasks: true}
      },
      [this.LOAD_PROJECT_TASKS_FAILURE]: (state) => {
        return {...state, requestingProjectTasks: false}
      },
      [this.LOAD_PROJECT_TASKS_SUCCESS] : (state, { result: { body: { data: projectTasks } } } ) => {
        return {...state, projectTasks, requestingProjectTasks: false}
      }
    })
  }

  static loadRecentProjectTasks(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(() => {
      return dispatch =>
        dispatch({
          type: this.LOAD_RECENT_PROJECT_TASKS,
          api: ProjectTaskAPI.autocomplete({ recent_tasks: true })
        })
    })

    reducer({
      [this.LOAD_RECENT_PROJECT_TASKS_SUCCESS] : (state, { result: { body: { data: projectTasks } } } ) => {
        return {...state, recentProjectTasks: projectTasks }
      }
    })
  }

  static createProjectTask(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(attributes => {
      return async dispatch => {
        await dispatch({
          type: this.CREATE_PROJECT_TASK,
          api: ProjectTaskAPI.create(attributes)
        })
        await dispatch(SnackBarActions.alert('Task created'))
      }
    })

    reducer({
      [this.CREATE_PROJECT_TASK]: (state) => {
        return state
      }
    })
  }

  static updateProjectTask(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((id, attributes) => {
      return async dispatch => {
        await dispatch({
          type: this.UPDATE_PROJECT_TASK,
          api: ProjectTaskAPI.update(id, attributes)
        })
        await dispatch(SnackBarActions.alert('Task updated'))
      }
    })

    reducer({
      [this.UPDATE_PROJECT_TASK]: (state) => {
        return state
      }
    })
  }

  static searchProjectTasks(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((query, order) => {
      return {
        type: this.SEARCH_PROJECT_TASKS,
        api: ProjectTaskAPI.search({...query, order})
      }
    })

    reducer({
      [this.SEARCH_PROJECT_TASKS_REQUEST]: (state) => {
        return {...state, requestingProjectTasks: true}
      },
      [this.SEARCH_PROJECT_TASKS_FAILURE]: (state) => {
        return {...state, requestingProjectTasks: false}
      },
      [this.SEARCH_PROJECT_TASKS_SUCCESS]: (state, {result: {body: {data: projectTasks}}}) => {
        return {...state, projectTasks, requestingProjectTasks: false}
      }
    })
  }

  static searchTimerProjectTasks(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((query, order) => {
      return {
        type: this.SEARCH_TIMER_PROJECT_TASKS,
        api: ProjectTaskAPI.search({...query, order})
      }
    })

    reducer({
      [this.SEARCH_TIMER_PROJECT_TASKS_SUCCESS]: (state, {result: {body: {data: timerProjectTasks}}}) => {
        return {...state, timerProjectTasks}
      }
    })
  }
}

export default new ProjectTaskActionSet()
