import React, {  } from 'react'
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem';
import {connect, ReactReduxContext} from 'react-redux'
import { UserActions } from '../../actionsets'
import CRUDForm from '../../containers/CRUDForm'
import { UserAPI } from '../../api'
import { RequestService } from '../../services'
import { push } from 'connected-react-router'
import { roleCheck } from '../../utils'
import { ModelStatus } from '../../constants'
import { LabeledSelect } from '../../components'


export class UserForm extends CRUDForm{

  static propTypes = {
    dispatch: PropTypes.func,
    match: PropTypes.object.isRequired,
    role: PropTypes.string,
    onSubmitted: PropTypes.func,
    onCancel: PropTypes.func,
  }

  static styles = {
    fields: {
      marginBottom: 20,
      marginTop: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    leftField: {
      flex: 1,
      marginRight: 10,
    },
    rightField: {
      flex: 1,
      marginLeft: 10,
    },
    actions: {
      float: 'right'
    }
  }

  constructor(props){
    super(props)
    this.state = {
      errors: {},
      submissionErrors: [],
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      role: 'user',
      status: ModelStatus.default,
      notificationTime: '17:00',
      workDays: 62,
      dailyHours: 8
    }
    UserActions.bindActions(this)
  }

  loadCreateItem = () => {
    if(this.createMode && !roleCheck(this.props.role, 'admin')){
      this.props.dispatch(push('/'))
    }
  }

  loadEditItem = async () => {
    if(this.userId !== this.editId && !roleCheck(this.props.role, 'admin')){
      this.props.dispatch(push('/'))
      return
    }
    try{
      const { body: { data: { attributes: { name, email, role, status, invitationAccepted, notificationTime, dailyHours, workDays }}}} = await UserAPI.show(this.editId)(RequestService)
      this.setState({
        name, email, role, status, invitationAccepted, notificationTime, dailyHours, workDays
      })
    }catch(err){
      this.setState({ submissionErrors: [{ detail: `Couldn't find user with id ${this.editId}`, source: { pointer:'' }}] })
    }
  }

  submit = async () => {

    if(!this.validate()) return

    const { name, email, role, status, password, passwordConfirmation, notificationTime, dailyHours, workDays} = {...this.state }

    const params = { name, email, role, status, notificationTime, dailyHours, workDays}
    if(password && passwordConfirmation){
      params.password = password
      params.passwordConfirmation = passwordConfirmation
    }

    let action

    if(this.editMode){
      action = this.actions.updateUser(this.editId, params)
    }else{
      action = this.actions.createUser(params)
    }

    try{
      await action
      this.props.onSubmitted && this.props.onSubmitted()
    }catch(err){
      const { body: { errors: submissionErrors }} = err
      this.setState({ submissionErrors })
    }
  }

  validate = () => {
    let requiredFields = ['name', 'email', 'role', 'status', 'notificationTime', 'dailyHours', 'workDays']

    const errors = this.requireFields(requiredFields)
    if((this.state.password || this.state.passwordConfirmation) && this.state.password !== this.state.passwordConfirmation){
      errors.passwordConfirmation = 'Password and confirmation do not match'
    }
    this.setState({ errors })
    return Object.keys(errors).length === 0
  }

  handleResendInvitation = () => {
    this.actions.resendInvitation(this.editId)
  }

  get editId(){
    return this.props.editId || this.props.match.params.editId
  }

  get adminUser(){
    return this.props.role === 'admin'
  }

  get userId(){
    return this.props.user.id
  }

  get roleSelectField(){
    return (
      <LabeledSelect
        style={UserForm.styles.leftField}
        label="Role"
        value={this.state.role}
        onChange={this.onInputValueChange('role')}
        {...this.fieldErrors('role')}
        disabled={!roleCheck(this.props.role, 'admin')}
      >
        <MenuItem value='admin' children='Admin' />
        <MenuItem value='user' children='User' />
      </LabeledSelect>
    )
  }

  get selfEdit() {
    return this.props.user.id === this.editId
  }

  renderPasswordFields = () =>
    <div style={{display: 'flex'}}>
      <TextField
        style={UserForm.styles.leftField}
        label={'Password ' + (this.editMode ? '(Optional)' : '')}
        type="password"
        value={this.state.password}
        onChange={this.onInputValueChange('password')}
        {...this.fieldErrors('password')}
      />
      <TextField
        style={UserForm.styles.rightField}
        label={'Password Confirmation ' + (this.editMode ? '(Optional)' : '')}
        type="password"
        value={this.state.passwordConfirmation}
        onChange={this.onInputValueChange('passwordConfirmation')}
        {...this.fieldErrors('passwordConfirmation')}
      />
    </div>

  resendInvitationButton = () =>
    <Button
      variant="contained"
      color="primary"
      style={{marginRight: 10}}
      onClick={this.handleResendInvitation}
    >Resend Invitation</Button>

  weekdays = [
    { text: "Monday", value: 2 },
    { text: "Tuesday", value: 4 },
    { text: "Wednesday", value: 8 },
    { text: "Thursday", value: 16 },
    { text: "Friday", value: 32 },
    { text: "Saturday", value: 64 },
    { text: "Sunday", value: 1 }
  ]

  render = () =>
    <div>
      <div style={UserForm.styles.fields}>

        {this.renderSubmissionErrors()}

        <div style={{display: 'flex'}}>

          <TextField
            style={UserForm.styles.leftField}
            label="Name"
            value={this.state.name}
            onChange={this.onInputValueChange('name')}
            {...this.fieldErrors('name')}
          />

          <TextField
            style={UserForm.styles.rightField}
            label="Email"
            value={this.state.email}
            onChange={this.onInputValueChange('email')}
            {...this.fieldErrors('email')}
            disabled={!roleCheck(this.props.role, 'admin')}
          />

        </div>

        <div style={{display: 'flex'}}>

          {this.roleSelectField}

          <LabeledSelect
           style={UserForm.styles.rightField}
           label="Status"
           value={this.state.status}
           onChange={this.onInputValueChange('status')}
           {...this.fieldErrors('status')}
           disabled={!roleCheck(this.props.role, 'admin')}>
            <MenuItem value='active' children='Active' />
            <MenuItem value='archived' children='Archived' />
          </LabeledSelect>

        </div>

        { this.selfEdit && this.renderPasswordFields() }

        <div style={{display: 'flex'}}>
          <LabeledSelect
           style={UserForm.styles.leftField}
           label="Notification Time"
           value={this.state.notificationTime}
           onChange={this.onInputValueChange('notificationTime')}
           {...this.fieldErrors('notificationTime')}>
            {['16','17','18','19','20'].flatMap(hour => ['00','15','30','45'].map(min => `${hour}:${min}`)).map(i =>
              <MenuItem key={i} value={i} children={i} />
            )}
          </LabeledSelect>
          <LabeledSelect
            style={UserForm.styles.rightField}
            label={"Work Days"}
            {...this.fieldErrors('workDays')}
            value={this.weekdays.filter(({value}) => ((this.state.workDays || 0) & value) > 0).map(({value}) => value)}
            multiple={true}
            onChange={({ target: { value }}) => this.setState({ workDays: value.reduce((a, b) => a | b, 0) })}
          >
            {this.weekdays.map(day => <MenuItem key={day.text} value={day.value} checked={((this.state.workDays || 0) & day.value) > 0} insetChildren={true} children={day.text} />)}
          </LabeledSelect>
          <LabeledSelect
            style={UserForm.styles.rightField}
            label="Hours Per Day"
            value={this.state.dailyHours}
            onChange={this.onInputValueChange('dailyHours')}
            {...this.fieldErrors('dailyHours')}>
              {[2,3,4,5,6,7,8,9,10,11,12].map(i =>
                <MenuItem key={i} value={i} children={i} />
              )}
          </LabeledSelect>
        </div>

      </div>
      <div style={UserForm.styles.actions}>
        <Button style={{marginRight: 10}} onClick={this.props.onCancel}>Cancel</Button>
        { this.editMode && !this.state.invitationAccepted && this.resendInvitationButton() }
        <Button variant="contained" color="primary" children={this.editMode ? 'Save' : 'Send Invitation'} onClick={this.submit} />
      </div>
    </div>
}

export default connect(({session}) => ({ ...session }), undefined, undefined, {context: ReactReduxContext})(UserForm)
