import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { ListView, ListViewColumn, TitleBar } from '../../components'
import Button from '@mui/material/Button'
import { ItemRenderers, ItemSorters } from '../../components/List'
import { UserActions } from '../../actionsets'
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper'
import { FilterTypes } from '../../constants'
import { FilterBar } from '../FilterBar'
import { UserForm } from '.'
import { connect } from "react-redux"
import Dialog from "../Dialog";

export class Users extends Component {

  static propTypes = {
    dispatch: PropTypes.func,
    users: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      selected: null,
      filterState: FilterTypes.ACTIVE
    }
    UserActions.bindActions(this)
  }

  componentDidMount = () => {
    this.loadUsers()
  }

  loadUsers = (query = this.filterToStatus(this.state.filterState)) => {
    this.actions.searchUsers(query)
  }

  userSelected = (selected) => {
    this.setState({ selected })
  }

  onFormSubmitted = success => {
    if (success) this.loadUsers()
    this.setState({ selected: null })
  }

  filterToStatus = filter => filter !== FilterTypes.ALL ? { status: filter.toLowerCase() } : {}

  onFilterChange = (filter) => {
    this.setState({ filterState: filter })
    this.loadUsers(this.filterToStatus(filter))
  }

  renderButtons = () => <>
    <Button style={{ float: 'right', marginLeft: 10 }} color="primary" variant="contained" onClick={() => this.setState({ selected: {} })}>New</Button>
    <FilterBar filter={this.state.filterState} onFilterChange={this.onFilterChange} />
  </>

  render = () =>
    <Paper style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <TitleBar>Users</TitleBar>
      <Card style={{ padding: 10 }}>
        <Dialog
          title={this.state.selected && this.state.selected.attributes ? 'Update User' : 'New User'}
          open={!!this.state.selected}
        >
          <UserForm
            {...this.props}
            editId={this.state.selected && this.state.selected.id}
            onSubmitted={() => this.onFormSubmitted(true)}
            onCancel={() => this.onFormSubmitted(false)}
          />
        </Dialog>


        <ListView
          pagination={{ pageSize: 10 }}
          items={this.props.users}
          sortable={true}
          defaultSortBy='name'
          defaultSortOrder='asc'
          filtering={true}
          onRowClick={this.userSelected}
          filterActions={this.renderButtons()}
        >

          <ListViewColumn name='Name' attribute='name' itemSorter={ItemSorters.stringPlainItemSorter} />
          <ListViewColumn name='Email' attribute='email' itemSorter={ItemSorters.stringPlainItemSorter} />
          <ListViewColumn name='Role' attribute='role' itemRenderer={ItemRenderers.titleizedItemRenderer} />
          <ListViewColumn name='Status' attribute='status' itemRenderer={ItemRenderers.titleizedItemRenderer} />
        </ListView>
      </Card>
    </Paper>
}

export default connect(({ user }) => user)(Users)