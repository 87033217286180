/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { FilterTypes } from '../constants'
import { connect } from 'react-redux'

export class FilterBar extends Component {

  static propTypes = {
    filter: PropTypes.oneOf(Object.keys(FilterTypes)).isRequired,
    onFilterChange: PropTypes.func.isRequired
  }

  formatFilterName = (filter) => {
    let [head, ...tail] = filter.toLowerCase().split('')
    return [head.toUpperCase(), ...tail].join('')
  }

  renderFilter = (filter, name) => {
    const selected = filter === this.props.filter
    return (
      <Button
        key={name}
        color={selected ? 'primary' : 'darkGray'}
        variant={selected ? 'contained' : 'outlined'}
        style={{ marginLeft: -1 }}
        onClick={this.props.onFilterChange.bind(null, filter)}>
        {name}
      </Button>
    )
  }

  renderFilters = (filters) => filters.map(filter => this.renderFilter(filter, this.formatFilterName(filter)))

  render = () =>
    <div css={{
      display: 'flex',
      justifyContent: 'flex-end',
      button: {
        borderRadius: 0,
      },
      'button:first-of-type': {
        borderRadius: '4px 0px 0px 4px',
      },
      'button:last-child': {
        borderRadius: '0px 4px 4px 0px',
      }
    }}>
      {this.renderFilters(Object.keys(FilterTypes))}
    </div>
}

export default connect(({ session }) => session)(FilterBar)
