import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import LinkIcon from '@mui/icons-material/Link'
import SectionWrapper from './SectionWrapper'
import Tooltip from '@mui/material/Tooltip'

export default class IconDropdownMenu extends Component {
  static styles = {
    mainIcon: {
      flex: 0,
      margin: '12px 0px'
    }
  }
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        logo: PropTypes.string,
        subItems: PropTypes.array
      })
    ).isRequired,
    onSelect: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      isShowing: false
    }
  }

  handleShow = (event) => {
    this.setState({ isShowing: true, anchorEl: event.currentTarget })
  }

  handleHide = () => {
    this.setState({ isShowing: false })
  }

  handleSelect = item => () => {
    this.handleHide()
    this.props.onSelect(item)
  }

  render = () => {
    const { items } = this.props

    return items.length ? (
      <>
        <IconButton onClick={this.handleShow}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          open={this.state.isShowing}
          anchorEl={this.state.anchorEl}
          style={IconDropdownMenu.styles.mainIcon}
          onClose={this.handleHide}
        >
          {items.map(item =>
            <SectionWrapper key={item.title} title={item.title}>
              {item.subItems.map(subItem =>
                <MenuItem
                  key={`${item.title}-${subItem.name}`}
                  onClick={this.handleSelect(subItem)}
                >
                  <ListItemIcon>
                    <img src={item.logoSrc} style={{ width: 30, height: 'auto' }} alt={item.title} />
                  </ListItemIcon>
                  <ListItemText primary={subItem.name} />
                  <ListItemSecondaryAction>
                    <Tooltip title={`View In ${item.title}`}>
                      <a href={subItem.url} target="_blank" rel="noopener noreferrer">
                        <LinkIcon />
                      </a>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </MenuItem>
              )}
            </SectionWrapper>
          )}
        </Menu>
      </>
    ) : null
  }
}
