import React, { Component } from 'react'
import PropTypes from 'prop-types';
import MaterialCardActions from '@mui/material/CardActions'
import { flatten, compact } from '../../utils'

export class CardActions extends Component{

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  }

  static styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#ECEFF7',
      flex: '0 0 50px',
      color: '#464646',
      fontSize: '1.1em',
      paddingLeft: 25,
      paddingBottom: 0,
      paddingTop: 10
    },
    separator: {
      borderLeft: '2px solid rgb(222, 220, 220)',
      display: 'inline-block',
      height: 34,
      margin: '-10px 12px 0 -4px'
    }
  }

  get childButtons(){
    return compact(flatten([this.props.children]))
  }

  get children(){
    const buttons = this.childButtons
    return flatten(buttons.map((child, index) =>
      (
        index === buttons.length - 1
      ) ? [child] : [child, <div key={`seperator-${index}`} style={CardActions.styles.separator}/>]
    ))
  }

  render = () =>
    <MaterialCardActions style={CardActions.styles.wrapper}>
      {this.children}
    </MaterialCardActions>

}

export default CardActions