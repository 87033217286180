import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { HorizontalCardLayout } from '../../components/cardLayout'

export class Main extends Component{

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    dispatch: PropTypes.func,
    routes: PropTypes.array
  }

  get path() {
    return this.props.routes && this.props.routes[3] && this.props.routes[3].path
  }

  onTabClick = (item) => {
    this.props.dispatch(push(`/home/${item.path}`))
  }

  render = () =>
    <HorizontalCardLayout>
      { this.props.children }
    </HorizontalCardLayout>
}

export default connect(({session}) => session)(Main)
